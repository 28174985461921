import {
    BioPage,
    CommissionsPage,
    GalleryPage,
    GamePage,
    HomePage,
    PortfolioPage,
    SocialPage,
    VonManager,
    VonPage
} from 'pages';
import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

export const Routes: React.FC = () => (
    <Switch>
        <Route component={HomePage} path='/home' />
        <Route component={BioPage} path='/bio' />
        <Route component={PortfolioPage} path='/portfolio' />
        <Route component={SocialPage} path='/social' />
        <Route component={CommissionsPage} path='/commissions' />

        <Route component={GalleryPage} path='/gallery' />
        <Route component={VonManager} path='/von/:id' />
        <Route component={VonPage} path='/von' />

        <Route component={GamePage} path='/test' />

        <Route render={() => <Redirect to='/home' />} />
    </Switch>
);