import {SoundConstants, SoundDispatch} from 'redux/constants';
import {store} from 'redux/store';

const startSound: () => SoundDispatch = () => ({
    type: SoundConstants.startSound
});

export const playSound = () => store.dispatch(startSound());

const changeSound: (src?: string) => SoundDispatch = (src) => ({
    type: SoundConstants.changeSound,
    src
});

export const setSound = (src?: string) => {
    store.dispatch(changeSound(src));
};