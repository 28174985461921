import {AreaEffectDescriptor} from 'von-game/maps/MapTypes';
import {SpriteDescriptor} from 'von-game/sprites/SpriteTypes';

import {EventDescriptor} from './EventTypes';
import {Directions, EVENT_REACH} from './GameConstants';
import {Coordinates, Shape, ShapeTypes} from './GameTypes';

/**
 * Returns the current hitbox of an entity depending on its effective hitbox and its position
 * @param hitbox The effective hitbox of the entity
 * @param position The position at which the hitbox should be calculated
 */
export const getCurrentHitbox: (hitbox?: Shape, position?: Coordinates) => Shape | undefined = (hitbox, position) => {
    if (!hitbox) {
        return undefined;
    }
    if (!position) {
        return {...hitbox};
    }
    return {
        start: {x: hitbox.start.x + position.x, y: hitbox.start.y + position.y},
        end: {x: hitbox.end.x + position.x, y: hitbox.end.y + position.y},
        plane: position.plane
    };
};

/**
 * Returns the hitbox in which a manual event can be recognized, depending on the character's
 * current hitbox and the direction they are facing.
 * @param currentHitbox The current hitbox of the character trying to trigger an event
 * @param facing The direction in which the character is facing
 */
export const getActionHitbox:
(currentHitbox?: Shape, facing?: string) => Shape | undefined = (currentHitbox, facing) => {
    if (!currentHitbox) {
        return undefined;
    }
    if (!facing) {
        return currentHitbox;
    }
    switch (facing) {
        case Directions.UP:
            return {
                start: {x: currentHitbox.start.x, y: currentHitbox.start.y - EVENT_REACH},
                end: {x: currentHitbox.end.x, y: currentHitbox.start.y},
                plane: currentHitbox.plane
            };
        case Directions.DOWN:
            return {
                start: {x: currentHitbox.start.x, y: currentHitbox.end.y},
                end: {x: currentHitbox.end.x, y: currentHitbox.end.y + EVENT_REACH},
                plane: currentHitbox.plane
            };
        case Directions.RIGHT:
            return {
                start: {x: currentHitbox.end.x, y: currentHitbox.start.y},
                end: {x: currentHitbox.end.x + EVENT_REACH, y: currentHitbox.end.y},
                plane: currentHitbox.plane
            };
        case Directions.LEFT:
            return {
                start: {x: currentHitbox.start.x - EVENT_REACH, y: currentHitbox.start.y},
                end: {x: currentHitbox.start.x, y: currentHitbox.end.y},
                plane: currentHitbox.plane
            };
        default:
            // Should never happen, but just in case returnthe current hitbox to avoid errors
            return currentHitbox;
    }
};

export const getZIndex = (hitbox?: Shape) => {
    if (!hitbox) {
        return 1;
    }
    // The z-index of a sprite is defined by the position of their hitbox on the map. Namely, a
    // sprite that is lower on the map will appear in front and a sprite that is higher on the map
    // will appear at the back. Virtual hitboxes can be used for specific effects.
    return hitbox.end.y;
};

/**
 * Returns the four lines of the rectangle
 * @param r The rectangle to destructure
 */
const getLines: (r: Shape) => Shape[] = (r) => [
    // TOP
    {
        start: r.start,
        end: {x: r.end.x, y: r.start.y},
        shapeType: ShapeTypes.line
    },
    // RIGHT
    {
        start: {x: r.end.x, y: r.start.y},
        end: r.end,
        shapeType: ShapeTypes.line
    },
    // BOTTOM
    {
        start: r.end,
        end: {x: r.start.x, y: r.end.y},
        shapeType: ShapeTypes.line
    },
    // LEFT
    {
        start: {x: r.start.x, y: r.end.y},
        end: r.start,
        shapeType: ShapeTypes.line
    }
];

/**
 * Returns true if the two lines intersect
 * @param l1 The first line
 * @param l2 The second line
 */
const intersectLine = (l1: Shape, l2: Shape) => {
    const det = (l1.end.x - l1.start.x) * (l2.end.y - l2.start.y) - (l2.end.x - l2.start.x) * (l1.end.y - l1.start.y);
    if (det === 0) {
        return false;
    }
    const lambda = ((l2.end.y - l2.start.y) * (l2.end.x - l1.start.x) + (l2.start.x - l2.end.x) * (l2.end.y - l1.start.y)) / det;
    const gamma = ((l1.start.y - l1.end.y) * (l2.end.x - l1.start.x) + (l1.end.x - l1.start.x) * (l2.end.y - l1.start.y)) / det;
    return (lambda > 0 && lambda < 1) && (gamma > 0 && gamma < 1);
};

/**
 * Returns true if the two rectangles intersect
 * @param r1 The first rectangle
 * @param r2 The second rectangle
 */
const intersectRectangle = (r1: Shape, r2: Shape) => (
    !(r2.start.x > r1.end.x
        || r2.end.x < r1.start.x
        || r2.start.y > r1.end.y
        || r2.end.y < r1.start.y)
);

/**
 * Returns true if the two shapes have at least on pixel of intersection
 * @param s1 The first shape
 * @param s2 The second shape
 */
export const intersect = (s1?: Shape, s2?: Shape) => {
    // If a shape is missing, no intersection possible
    if (!s1 || !s2) {
        return false;
    }
    // If the shapes are on two different planes, no intersection possible
    if (s1.plane && s2.plane && s1.plane !== s2.plane) {
        return false;
    }
    if (s1.shapeType === ShapeTypes.line && s2.shapeType === ShapeTypes.line) {
        return intersectLine(s1, s2);
    }
    if (s1.shapeType === ShapeTypes.line) {
        // Check for any intersection between the line s1 and each line of the rectangle s2
        return getLines(s2).some((line) => intersectLine(s1, line));
    }
    if (s2.shapeType === ShapeTypes.line) {
        // Check for any intersection between the line s2 and each line of the rectangle s1
        return getLines(s1).some((line) => intersectLine(s2, line));
    }
    return intersectRectangle(s1, s2);
};

/**
 * Try to move along a tilted wall proportionally to its angle
 * @param p0 The previous position
 * @param p1 The attempted new position that result in a collision with the wall
 * @param wall The collided wall
 * @param fc The friction coefficient
 * @returns The updated position to recheck
 */
export const getFrictionUpdatedPosition: (p0: Coordinates, p1: Coordinates, wall: Shape, fc?: number) => Coordinates = (p0, p1, wall, fc = 0.8) => {
    const a = p1.x - p0.x;
    const b = p1.y - p0.y;
    const c = wall.end.x - wall.start.x;
    const d = wall.end.y - wall.start.y;
    const r = (a * c + b * d) / (c ** 2 + d ** 2);
    return {
        x: Math.round(p0.x + r * c * fc),
        y: Math.round(p0.y + r * d * fc)
    };
};

export const listSpriteEvents: (sprites: SpriteDescriptor[]) => EventDescriptor[] = (sprites) => sprites.flatMap((sprite) => sprite.events.map((event) => ({...event, hitbox: getCurrentHitbox(sprite.hitbox, sprite.position)})));

/**
 * Returns true if the specified keys contain all of the required keys
 * @param requiredKeys The keys needed
 * @param keys The keys possessed
 */
export const checkRequiredKeys = (requiredKeys: string[], keys?: string[]) => requiredKeys.filter((key) => keys && keys.includes(key)).length === requiredKeys.length;

/**
 * Returns true if none of the specified keys are contained in the forbidden keys
 * @param forbiddenKeys The keys that shouldn't be possessed
 * @param keys The keys possessed
 */
export const checkForbiddenKeys = (forbiddenKeys: string[], keys?: string[]) => !forbiddenKeys.filter((key) => keys && keys.includes(key)).length;

export const getManualEvent = (events: EventDescriptor[], actionHitbox?: Shape, eventKeys?: string[]) => events.find((event) => event.manual && checkRequiredKeys(event.requiredKeys, eventKeys) && checkForbiddenKeys(event.forbiddenKeys, eventKeys) && intersect(actionHitbox, event.hitbox));

export const getAutomaticEvent = (events: EventDescriptor[], actionHitbox?: Shape, eventKeys?: string[]) => events.find((event) => !event.manual && checkRequiredKeys(event.requiredKeys, eventKeys) && checkForbiddenKeys(event.forbiddenKeys, eventKeys) && intersect(actionHitbox, event.hitbox));

export const getArea = (areas: AreaEffectDescriptor[], hitbox?: Shape) => areas.find((area) => intersect(hitbox, area.area));