import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {startGame} from 'von-game/GameContainer';
import {EventType, PlayTypes} from 'von-game/utils/EventTypes';
import {ActionKeys, DirectionKeys} from 'von-game/utils/GameConstants';

import {addPendingEvent} from './9_EventContainer';

const Container = styled.div`
    width: var(--game-frame-width);
    height: var(--game-frame-height);
    overflow: hidden;
    background: url("/assets/Title.png") no-repeat;
    background-size: cover;

    .title-actions {
        position: absolute;
        top: 250px;
        left: 50px;
        font-size: 40px;
        color: black;

        .title-action.selected {
            color: red;
        }

        .title-action.disabled {
            color: gray;
        }
    }
`;

const newGame = () => {
    addPendingEvent({
        type: EventType.mapChange,
        forbiddenKeys: [],
        requiredKeys: [],
        newMap: 'NEON_BLOSSOM',
        newPosition: () => ({x: 200, y: 450, plane: 100}),
        nextEvents: [{
            type: EventType.sound,
            forbiddenKeys: [],
            requiredKeys: [],
            soundPack: {
                name: 'hypnosis',
                sounds: [
                    {identifier: 'hypnosis-base', src: '/sounds/tracks/hypnosis_base.wav', loop: true},
                    {identifier: 'hypnosis-dynamic', src: '/sounds/tracks/hypnosis_dynamic_layer.wav', loop: true}
                ],
                activeSound: 'hypnosis-base'
            },
            playType: PlayTypes.replace,
            nextEvents: []
        }]
    });
    startGame();
};

type TitleAction = {
    name: string,
    onSelect: () => void,
    disabled: boolean
};

const TITLE_ACTIONS: TitleAction[] = [
    {
        name: 'NEW GAME',
        onSelect: newGame,
        disabled: false
    },
    {
        name: 'CONTINUE',
        onSelect: () => null,
        disabled: true
    },
    {
        name: 'OPTIONS',
        onSelect: () => null,
        disabled: true
    },
    {
        name: 'EXIT',
        onSelect: () => null,
        disabled: true
    }
];

const mod = (n, m) => ((n % m) + m) % m;

export const TitleScreenContainer: React.FC = () => {
    const [choiceIndex, setChoiceIndex] = useState(0);

    const updateChoiceIndex = useCallback((value) => {
        let newIndex = mod(choiceIndex + value, TITLE_ACTIONS.length);
        while (TITLE_ACTIONS[newIndex].disabled) {
            newIndex = mod(newIndex + value, TITLE_ACTIONS.length);
        }
        setChoiceIndex(newIndex);
    }, [choiceIndex, setChoiceIndex]);

    const keyDownProcessor = useCallback((e) => {
        const direction = DirectionKeys[e.code];
        if (direction) {
            switch (direction) {
                case 'down':
                case 'right':
                    updateChoiceIndex(1);
                    break;
                case 'up':
                case 'left':
                    updateChoiceIndex(-1);
                    break;
                default:
                    break;
            }
        }

        const action = ActionKeys[e.code];
        if (action) {
            TITLE_ACTIONS[choiceIndex].onSelect();
        }
    }, [choiceIndex, updateChoiceIndex]);


    useEffect(() => {
        document.addEventListener('keydown', keyDownProcessor);
        return () => document.removeEventListener('keydown', keyDownProcessor);
    }, [keyDownProcessor]);

    const displayActions = useCallback(() => TITLE_ACTIONS.map((action, index) => (
        <div key={action.name} className={`title-action${action.disabled ? ' disabled' : ''}${index === choiceIndex ? ' selected' : ''}`}>
            {action.name}
        </div>
    )), [choiceIndex]);

    return (
        <Container className='title-frame'>
            <div className='title-actions'>
                {displayActions()}
            </div>
        </Container>
    );
};