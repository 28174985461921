import {ClassicLink, Image, Page} from 'components';
import React, {useEffect} from 'react';
import {changeBackgroundImage} from 'redux/actions';
import {BACKGROUNDS, PICTURES} from 'redux/constants';

export const GalleryPage: React.FC = () => {
    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.STREET3);
    }, []);

    return (
        <Page>
            <h1>GALLERY</h1>
            <Image src={PICTURES.ART1} redirect='https://twitter.com/Lilblueorchid' />
            <Image src={PICTURES.ART2} redirect='https://twitter.com/Lilblueorchid' />
            <Image src={PICTURES.ART3} redirect='https://twitter.com/Lilblueorchid' />
            <Image src={PICTURES.ART4} redirect='https://twitter.com/Lilblueorchid' />
            <Image src={PICTURES.ART5} redirect='https://twitter.com/Lilblueorchid' />
            <Image src={PICTURES.ART6} redirect='https://twitter.com/Lilblueorchid' />
            <Image src={PICTURES.ART7} redirect='https://twitter.com/Lilblueorchid' />
            <Image src={PICTURES.ART8} redirect='https://twitter.com/Lilblueorchid' />
            <Image src={PICTURES.ART8_BIS} redirect='https://twitter.com/Lilblueorchid' />
            <Image src={PICTURES.ART9} redirect='https://twitter.com/Lilblueorchid' />
            <Image src={PICTURES.ART10} redirect='https://twitter.com/yueko__' />
            <Image src={PICTURES.ART11} redirect='https://twitter.com/eieio35' />
            <Image src={PICTURES.ART12} redirect='https://twitter.com/soyacomu' />
            <ClassicLink to={'/home'}>BACK</ClassicLink>
        </Page>
    );
};