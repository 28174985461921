import {CHARACTERS} from 'von-game/sprites';
import {Coordinates, GameStateDescriptor} from 'von-game/utils/GameTypes';
import {getCurrentHitbox, getZIndex} from 'von-game/utils/GameUtils';

let GAME_STATE: GameStateDescriptor = {
    currentCharacter: 'LISA',
    currentMap: '',
    currentPosition: {x: 0, y: 0, plane: 0},
    currentEventKeys: []
};
export const getGameState = () => GAME_STATE;

export const changeCharacter = (character: string) => {
    GAME_STATE.currentCharacter = character;
};

export const getCharacterZIndex = () => {
    const {currentCharacter, currentPosition} = GAME_STATE;
    const {hitbox} = CHARACTERS[currentCharacter];
    const currentHitbox = getCurrentHitbox(hitbox, currentPosition);
    return getZIndex(currentHitbox);
};

export const getCharacterPlane = () => GAME_STATE.currentPosition.plane;

export const setMap = (map: string) => {
    GAME_STATE.currentMap = map;
};

export const setPosition = (position: Coordinates) => {
    GAME_STATE.currentPosition = {
        ...GAME_STATE.currentPosition,
        ...position
    };
};

export const redefinePosition = (positionDefinition: (oldPosition: Coordinates) => Coordinates) => {
    GAME_STATE.currentPosition = {
        ...GAME_STATE.currentPosition,
        ...positionDefinition(GAME_STATE.currentPosition)
    };
};

export const setPlane = (plane: number) => {
    GAME_STATE.currentPosition.plane = plane;
};

export const redefinePlane = (planeDefinition: (oldPlane?: number) => number) => {
    GAME_STATE.currentPosition.plane = planeDefinition(GAME_STATE.currentPosition.plane);
};

/** Adds an event key to the current keys. */
export const addEventKey = (key?: string) => {
    if (!key) {
        return;
    }
    const index = GAME_STATE.currentEventKeys.indexOf(key);
    if (index < 0) {
        GAME_STATE.currentEventKeys.push(key);
    }
};

/** Removes an event key from the current keys, if it exists. */
export const removeEventKey = (key?: string) => {
    if (!key) {
        return;
    }
    const index = GAME_STATE.currentEventKeys.indexOf(key);
    if (index > -1) {
        GAME_STATE.currentEventKeys.splice(index, 1);
    }
};

/** Save the current state of the game to load it later. */
export const saveGameState = (slot: number = 0) => {
    // Get the state as a json string
    let str = JSON.stringify(GAME_STATE);
    // Encode the string
    str = encodeURI(str);
    // Store the encoded string in the indexed DB using the specified slot
    return str;
};

export const loadGameState = (slot: number = 0) => {
    // Load the encoded string from the indexed DB using the specified slot
    let str = '';
    // If there is no stored state, ignore the load
    if (!str) {
        return;
    }
    // Decode the string
    str = decodeURI(str);
    // Recreate the object from the string
    GAME_STATE = {
        ...JSON.parse(str)
    };
};

export const exportStateAsHash = () => {
    // Get the state as a json string
    const str = JSON.stringify(GAME_STATE);
    // Encode the string
    return encodeURI(str);
};

export const loadStateFromHash = (hash: string) => {
    // If the specified string is empty, ignore the load
    if (!hash) {
        return;
    }
    // Decode the string
    const str = decodeURI(hash);
    // Recreate the object from the string
    GAME_STATE = {
        ...JSON.parse(str)
    };
};