import {ClassicLink, Column, Page} from 'components';
import React from 'react';

function submitToDiscord(event): void {
    event.preventDefault();
    const data = new FormData(event.target);
    const request = new XMLHttpRequest();
    request.open('POST', 'https://discordapp.com/api/webhooks/708541902596735236/Uz8y_3Xswhrgeh-1fEAIWN-ZNJVhckXRgykXM3xb_SR5EkgBowMqqpVldbgOcnesmd1H');
    request.setRequestHeader('Content-type', 'application/json');
    const params = {
        content: `${data.get('username')} says:
        ${data.get('comment')}`
    };
    request.send(JSON.stringify(params));
}

export const Comment: React.FC = () => (
    <Page>
        <h1>VON</h1>
        <form onSubmit={submitToDiscord}>
            <Column>
                <input name='username' type='text' placeholder='Username' required />
                <textarea name='comment' placeholder='Comment...' />
                <button type='submit'>Send</button>
            </Column>
        </form>
        <ClassicLink to={'/von'}>BACK</ClassicLink>
    </Page>
);