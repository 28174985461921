const CHANGE_BACKGROUND = 'CHANGE_BACKGROUND';

interface ChangeBackgroundDispatch {
    type: typeof CHANGE_BACKGROUND;
    backgroundUrl: string;
}

export const BACKGROUNDS = {
    NEON_BLOSSOM: '/documents/backgrounds/neonblossom.png',
    STREET1: '/documents/backgrounds/street1.png',
    STREET2: '/documents/backgrounds/street2.png',
    STREET3: '/documents/backgrounds/street3.png',
    LISA: '/documents/backgrounds/background_lisa.jpg'
};

export const PICTURES = {
    ART1: '/documents/images/art1.jpg',
    ART2: '/documents/images/art2.jpg',
    ART3: '/documents/images/art3.png',
    ART4: '/documents/images/art4.jpg',
    ART5: '/documents/images/art5.jpg',
    ART6: '/documents/images/art6.jpg',
    ART7: '/documents/images/art7.jpg',
    ART8: '/documents/images/art8.png',
    ART8_BIS: '/documents/images/art8bis.jpg',
    ART9: '/documents/images/art9.jpg',
    ART10: '/documents/images/art10.jpg',
    ART11: '/documents/images/art11.jpg',
    ART12: '/documents/images/art12.jpg',
    LISA_PORTRAIT: '/documents/images/lisa.png'
};

export const BackgroundConstants = {
    changeBackground: CHANGE_BACKGROUND as typeof CHANGE_BACKGROUND
};

export type BackgroundDispatch =
    ChangeBackgroundDispatch;