import React, {useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {useParams} from 'react-router-dom';
import {changeSong, setSound} from 'redux/actions';
import {SONGS, SOUNDS} from 'redux/constants';
import {RootState} from 'redux/reducers';

import {Chapter1, Chapter1Extra, Lost, Prologue} from './von_pages';

const mapStateToProps = (state: RootState) => ({
    vaultCode: state.app.vaultCode
});

const connector = connect(mapStateToProps);

type VonManagerConnectedProps = ConnectedProps<typeof connector>;

type VonManagerProps = VonManagerConnectedProps & {

}

const VonManager: React.FC<VonManagerProps> = (props) => {
    const [component, setComponent] = useState(<Lost />);
    const {id} = useParams();

    /**
     * INDEX:
     * 1 -> Chapter 1
     * 2 -> Easter egg 1
     * 3 -> Chapter 2
     * 4 -> Chapter 3
     * 5 -> Chapter 4
     * 6 -> Chapter 5
     * lisa -> Chapter 6
     * {8-char safe combination} -> Extra 1
     * 01101997 -> Easter egg 2
     * {puzzle 2 answer} -> Extra 2
     * {6-char puzzle 3 answer} -> Extra 3
     * memory -> Easter egg 4
     */

    useEffect(() => {
        // Process dynamic ids
        if (id === props.vaultCode) {
            changeSong(SONGS.here);
            setComponent(<Chapter1Extra />);
            return;
        }
        // Process static ids
        switch (id) {
        case '0':
            setSound(SOUNDS.metro);
            changeSong(SONGS.mediumWarm);
            setComponent(<Prologue />);
            break;
        case '1':
            setSound(SOUNDS.door);
            changeSong(SONGS.didYouKnow);
            setComponent(<Chapter1 />);
            break;
        default:
            setComponent(<Lost />);
        }
    }, [id, props.vaultCode]);

    useEffect(() => () => {
        setSound();
        changeSong();
    }, []);

    return component;
};

export const ConnectedVonManager = connector(VonManager);