import {ModalProps} from 'components/modals/ModalProps';
import {ModalConstants, ModalDispatch} from 'redux/constants';

export class ModalState {
    modal: ModalProps | null = null;
}

export function ModalReducer(
    state = new ModalState(),
    action: ModalDispatch
): ModalState {
    if (!action) return state;
    switch (action.type) {
    case ModalConstants.openModal:
        return {
            ...state,
            modal: action.modal
        };
    case ModalConstants.closeModal:
        return {
            ...state,
            modal: null
        };
    default:
        return state;
    }
}