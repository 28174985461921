import {CafeTopSprite} from './CafeTopSprite';
import {Chair1Sprite} from './Chair1Sprite';
import {Chair2Sprite} from './Chair2Sprite';
import {InvisibleWallSprite} from './InvisibleWallSprite';
import {MallFrontColumnSprite} from './MallFrontColumnSprite';
import {MallLeftColumnSprite} from './MallLeftColumnSprite';
import {MallRightColumnSprite} from './MallRightColumnSprite';
import {MallTopSprite} from './MallTopSprite';
import {MenuSprite} from './MenuSprite';
import {PatternsLeftTopSprite} from './PatternsLeftTopSprite';
import {PlotSprite} from './PlotSprite';
import {StreetLampSprite} from './StreetLampSprite';
import {TableSprite} from './TableSprite';
import {TrafficLightSprite} from './TrafficLightSprite';

export const ObjectSprites = {
    MallFrontColumnSprite,
    MallLeftColumnSprite,
    MallRightColumnSprite,
    PatternsLeftTopSprite,
    CafeTopSprite,
    MallTopSprite,
    Chair1Sprite,
    Chair2Sprite,
    MenuSprite,
    PlotSprite,
    StreetLampSprite,
    TableSprite,
    TrafficLightSprite,
    InvisibleWallSprite
};