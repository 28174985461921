import {ClassicLink, Page, Video} from 'components';
import React, {useEffect} from 'react';
import {changeBackgroundImage, pauseMusic, playMusic} from 'redux/actions';
import {BACKGROUNDS} from 'redux/constants';

export const PortfolioPage: React.FC = () => {
    useEffect(() => {
        pauseMusic();
        return () => {
            playMusic();
        };
    }, []);

    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.LISA);
    }, []);

    return (
        <Page>
            <h1>PORTFOLIO</h1>
            <Video name='End of a Life' shortUrl='BXB26PzV31k'></Video>
            <Video name='Deja Vu' shortUrl='pxtGwAtBSjQ'></Video>
            <Video name='52 Hearts' shortUrl='JNsYaefCe-Y'></Video>
            <Video name='Tides' shortUrl='baQrdfSHrII'></Video>
            <Video name='Weight of the World' shortUrl='dIGP72gk5Qo'></Video>
            <Video name='Silent Forever' shortUrl='cdvdc-KxvhM'></Video>
            <ClassicLink to={'/home'}>BACK</ClassicLink>
        </Page>
    );
};