import {clearDirections} from 'von-game/GameContainer';
import {MAPS} from 'von-game/maps';
import {MAP_IDENTIFIER} from 'von-game/maps/MapTypes';
import {CHARACTER_IDENTIFIER, SpriteDescriptor} from 'von-game/sprites/SpriteTypes';
import {EventDescriptor, EventType} from 'von-game/utils/EventTypes';
import {checkForbiddenKeys, checkRequiredKeys} from 'von-game/utils/GameUtils';

import {addEventKey, getGameState, redefinePlane, removeEventKey} from './0_StateContainer';
import {displayDiscussion} from './4_DialogContainer';
import {
    setCharacterAttribute,
    setCharacterPosition,
    setMapPosition,
    setSpritePosition,
    startMapChange
} from './6_MapContainer';
import {playSound, playSoundPack} from './9_SoundContainer';

// Events variables
const currentEvents: EventDescriptor[] = [];
let isEventOngoing = false;
export const getEventOngoing = () => isEventOngoing;
let isAutomaticEvent = false;
let eventCounter = 0;
let timeLoop = 0;

export const incrementTimeLoop = () => {
    if (timeLoop > 504000) {
        timeLoop = 0;
    } else {
        timeLoop++;
    }
};

/**
 * Adds a new event to the list that will be processed after the current event is finished or the
 * next time the event container gets priority in the loop.
 */
export const addPendingEvent = (event: EventDescriptor) => {
    currentEvents.push(event);
};

/** Removes the current event from the list and allow a new event to be started */
export const switchToNextEvent = (index?: number) => {
    const {nextEvents = []} = currentEvents[0] || {};
    // Add the next event to the list if it is defined
    if (nextEvents.length) {
        currentEvents.push(nextEvents[index || 0]);
    }
    // Remove the current event fom the list
    currentEvents.shift();
    // Reset variables for the next event
    isEventOngoing = false;
    isAutomaticEvent = false;
    eventCounter = 0;
};

const BreakException = {};
const processAutomaticEvent = () => {
    const currentEvent = currentEvents[0];
    if (!currentEvent || currentEvent.type !== EventType.automatic) {
        isAutomaticEvent = false;
        return;
    }
    const {manipulations} = currentEvent;
    try {
        const {currentPosition, currentMap, currentEventKeys} = getGameState();
        manipulations.forEach(({identifier, checkEnd, positionDefinition, walking}) => {
            if (identifier === CHARACTER_IDENTIFIER) {
                if (checkEnd && checkEnd(currentPosition, eventCounter)) {
                    setCharacterAttribute('walking', 'false');
                    throw BreakException;
                }
                const newPosition = {
                    ...currentPosition,
                    ...positionDefinition(currentPosition, eventCounter, currentEventKeys, null)
                };
                setCharacterAttribute('walking', walking ? 'true' : 'false');
                setCharacterPosition(newPosition);
            } else if (identifier === MAP_IDENTIFIER) {
                if (checkEnd && checkEnd(currentPosition, eventCounter)) {
                    throw BreakException;
                }
                const newPosition = {
                    ...currentPosition,
                    ...positionDefinition(currentPosition, eventCounter, currentEventKeys, null)
                };
                setMapPosition(newPosition);
            } else {
                const {sprites} = MAPS[currentMap];
                const filteredSprites = sprites.filter((sprite) => sprite.identifier === identifier);
                if (!filteredSprites.length) {
                    throw BreakException;
                }
                filteredSprites.forEach((sprite) => {
                    const spriteElement: any = document.querySelector(`.${sprite.identifier}`);
                    if (!spriteElement) {
                        throw BreakException;
                    }
                    if (checkEnd && checkEnd(sprite.position, eventCounter)) {
                        spriteElement.setAttribute('walking', 'false');
                        throw BreakException;
                    }
                    sprite.position = {
                        ...sprite.position,
                        ...positionDefinition(sprite.position, eventCounter, currentEventKeys, spriteElement)
                    };
                    setSpritePosition(sprite);
                    spriteElement.setAttribute('walking', walking ? 'true' : 'false');
                });
            }
        });
    } catch (e) {
        switchToNextEvent();
    }
    eventCounter += 1;
};

// Process the current event (change the display, move the characters or the map, change elements,
// ...)
export const processEvent = () => {
    // If an event is already playing, keep processing that event
    if (isEventOngoing) {
        if (isAutomaticEvent) {
            processAutomaticEvent();
        }
        return true;
    }
    const currentEvent = currentEvents[0];
    // If there are no events to process, notify the caller
    if (!currentEvent) {
        return false;
    }
    // Process the first event from the list
    isEventOngoing = true;

    switch (currentEvent.type) {
        case EventType.automatic:
            eventCounter = 0;
            isAutomaticEvent = true;
            break;
        case EventType.choice:
            displayDiscussion(currentEvent.discussion, currentEvent.choice);
            clearDirections();
            setCharacterAttribute('walking', 'false');
            break;
        case EventType.dialog:
            displayDiscussion(currentEvent.discussion);
            clearDirections();
            setCharacterAttribute('walking', 'false');
            break;
        case EventType.mapChange:
            startMapChange(currentEvent.newMap, currentEvent.newPosition);
            break;
        case EventType.shift:
            redefinePlane(currentEvent.newPlane);
            switchToNextEvent();
            break;
        case EventType.sound:
            if (currentEvent.sound) {
                playSound(currentEvent.sound, currentEvent.playType);
            }
            if (currentEvent.soundPack) {
                playSoundPack(currentEvent.soundPack, currentEvent.playType);
            }
            switchToNextEvent();
            break;
        default:
            switchToNextEvent();
            break;
    }
    return true;
};

export const processSpriteEvent = (sprite: SpriteDescriptor, spriteElement: any) => {
    const {currentEventKeys} = getGameState();
    // Make sure the movement can be processed
    const {movementEvent, position} = sprite;
    if (!movementEvent || !(movementEvent.type === EventType.automatic)) {
        return;
    }
    if (!checkRequiredKeys(movementEvent.requiredKeys, currentEventKeys)
        || !checkForbiddenKeys(movementEvent.forbiddenKeys, currentEventKeys)) {
        return;
    }
    const {manipulations} = movementEvent;
    if (!manipulations || manipulations.length === 0) {
        return;
    }

    // Process the movement to update the sprite position
    const {checkEnd, positionDefinition: newPosition, walking} = manipulations[0];
    sprite.position = {
        ...position,
        ...newPosition(position, timeLoop, currentEventKeys, spriteElement)
    };

    if (checkEnd && checkEnd(sprite.position, timeLoop)) {
        spriteElement.setAttribute('walking', 'false');
        // If the movement is ongoing, add the event key if it is defined
        removeEventKey(movementEvent.eventKey);
    } else {
        spriteElement.setAttribute('walking', walking ? 'true' : 'false');
        // If the movement is finished, remove the event key if it is defined
        addEventKey(movementEvent.eventKey);
    }
};