import React from 'react';

type ImageProps = {
    src: string,
    alt?: string;
    redirect?: string;
}

export const Image: React.FC<ImageProps> = (props) => (
    <React.Fragment>
        {props.redirect
            ? <a
                target='_blank'
                rel='noopener noreferrer'
                href={props.redirect}
            >
                <img src={props.src} alt={props.alt} />
            </a>
            : <img src={props.src} alt={props.alt} />
        }
    </React.Fragment>
);