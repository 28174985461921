import {ClassicLink, Page} from 'components';
import React, {useEffect} from 'react';
import {changeBackgroundImage} from 'redux/actions';
import {BACKGROUNDS} from 'redux/constants';

export const BioPage: React.FC = () => {
    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.LISA);
    }, []);

    return (
        <Page align='left'>
            <h1>BIOGRAPHY</h1>
            <p>
                {"Hi! I'm Diego / Patterns, a french based composer/producer."}
            </p>
            <p>
                {`I've been creating original music for video games, singers and vtubers as my full
                time job since 2021. As of today, I've released original songs with indie artists
                as well as larger corporations such as Nijisanji or Hololive and many other
                arrangement and remix projects.`}
            </p>
            <p>
                {`I'm currently in the process of composing video game soundtracks for "Studio
                Chiffon" & "For the Quest Studios" as well as composing for my own projects along
                the way.`}
            </p>
            <p>
                {"Let's work together on your next project!"}
            </p>
            <ClassicLink to={'/home'}>BACK</ClassicLink>
        </Page>
    );
};