import React from 'react';
import styled from 'styled-components';

import {SpriteProps} from '../SpriteTypes';

const Container = styled.div`
    // The real dimensions of the sprite (EDIT_HERE)
    width: 186px;
    height: 96px;
    position: absolute;
    overflow: hidden;
    opacity: 0;
    transition: opacity .3s;

    .spritesheet {
        position: absolute;
        background: url("/assets/game/maps/neon_blossom/mall_right_column.png") no-repeat no-repeat;
        background-size: 100%;
        // The real dimensions of the spritesheet (EDIT_HERE)
        width: 186px;
        height: 96px;
    }
`;

export const MallRightColumnSprite: React.FC<SpriteProps> = (props) => (
    <Container className={`${props.identifier} sprite`}>
        <div className='spritesheet pixel-art'></div>
    </Container>
);