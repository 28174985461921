import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    height: 56.8px;
`;

export const EmptyLink: React.FC = (props) => (
    <Container>
        {props.children}
    </Container>
);