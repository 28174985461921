import React, {useCallback, useEffect} from 'react';
import styled, {css} from 'styled-components';
import {closeMenu, getMenuOpen, openMenu} from 'von-game/GameContainer';
import {ActionKeys, DirectionKeys} from 'von-game/utils/GameConstants';

const Container = styled.div<{
    menuOpen: boolean
}>`
    width: 800px;
    height: 500px;
    background-color: black;
    position: absolute;
    overflow: hidden;
    top: 50px;
    left: 100px;
    opacity: 0;
    border-radius: 50px;

    animation: ${(props) => (props.menuOpen ? css`
        open 600ms cubic-bezier(0.68, -0.6, 0.32, 1.6) 0s 1 normal forwards;
    ` : css`
        close 600ms cubic-bezier(0.68, -0.6, 0.32, 1.6) 0s 1 normal forwards;
    `)}

    @keyframes open {
        0% {
            opacity: 0;
            transform: scale(0.6);
        }

        100% {
            opacity: 0.7;
            transform: scale(1);
        }
    }
    @keyframes close {
        0% {
            opacity: 0.7;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(0.6);
        }
    }

`;

export const MenuScreenContainer: React.FC<{
    menuOpen: boolean
}> = ({menuOpen}) => {
    const keyDownProcessor = useCallback((e) => {
        if (!getMenuOpen()) {
            if (ActionKeys[e.code] === ActionKeys.Escape) {
                openMenu();
            }
            return;
        }
        const direction = DirectionKeys[e.code];
        if (direction) {
            switch (direction) {
                case 'down':
                case 'right':
                    break;
                case 'up':
                case 'left':
                    break;
                default:
                    break;
            }
        }

        const action = ActionKeys[e.code];
        if (action) {
            if (action === ActionKeys.Escape) {
                closeMenu();
            }
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keyup', keyDownProcessor);
        return () => document.removeEventListener('keyup', keyDownProcessor);
    }, [keyDownProcessor]);

    return (
        <Container className={'menu-frame'} menuOpen={menuOpen}>
            <div className='spritesheet pixel-art'/>
        </Container>
    );
};