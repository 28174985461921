import React from 'react';
import styled from 'styled-components';

type StyleProps = {
    width: string,
    align: string
}

const Container = styled.div<StyleProps>`
    ${(props) => `width: ${props.width};`}
    height: 100%;
    ${(props) => `text-align: ${props.align};`}
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
`;

type ColumnProps = {
    width?: string,
    align?: string
}

export const Column: React.FC<ColumnProps> = ({
    align = 'left',
    children,
    width = '100%'
}) => (
    <Container width={width} align={align}>
        {children}
    </Container>
);