import {HomeLink} from 'components';
import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {changeBackgroundImage} from 'redux/actions';
import {BACKGROUNDS} from 'redux/constants';
import {RootState} from 'redux/reducers';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
    color: white;

    .home-links {
        margin-top: 12%;
        display: flex;
        flex-flow: column;
        flex: 1 1 auto;

        @media screen and (max-device-width: 600px) {
            margin-left: 30px;
        }

        div {
            margin-right: auto;
            animation: fade-in-translate .5s ease-in-out;
            animation-fill-mode: both;

            :nth-child(1) { animation-delay: .3s }
            :nth-child(2) { animation-delay: .4s }
            :nth-child(3) { animation-delay: .5s }
            :nth-child(4) { animation-delay: .6s }
            :nth-child(6) { animation-delay: .7s }
        }
    }

    @keyframes fade-in-translate {
        0% {
            opacity: 0;
            transform: translateX(-140px);
        }
        60% {
            opacity: 1;
            transform: translateX(20px);
        }
        87% {
            opacity: 1;
            transform: translateX(-30px);
        }
        100% {
            transform: translateX(0px);
        }
    }
`;

const mapStateToProps = (state: RootState) => ({
    isAppStarted: state.app.isStarted
});

const connector = connect(mapStateToProps);

type HomePageConnectedProps = ConnectedProps<typeof connector>;

type HomePageProps = HomePageConnectedProps & {

}

const HomePage: React.FC<HomePageProps> = (props) => {
    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.NEON_BLOSSOM);
    }, []);

    return (
        <Container>
            {props.isAppStarted && (
                <div className='home-links'>
                    <HomeLink to={'/bio'}>BIOGRAPHY</HomeLink>
                    <HomeLink to={'/portfolio'}>PORTFOLIO</HomeLink>
                    <HomeLink to={'/social'}>SOCIALS</HomeLink>
                    <HomeLink to={'/commissions'}>COMMISSIONS</HomeLink>
                    {/*
                    <HomeLink to={'/von'}>VON</HomeLink>
                    <EmptyLink />
                    <HomeLink to={'/gallery'}>GALLERY</HomeLink>
                    */}
                </div>
            )}
        </Container>
    );
};

export const ConnectedHomePage = connector(HomePage);