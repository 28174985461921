import {SoundConstants, SoundDispatch} from 'redux/constants';

export class SoundState {
    isPlaying: boolean = false;
    src?: string;
    increment: number = 0;
}

export function SoundReducer(
    state = new SoundState(),
    action: SoundDispatch
): SoundState {
    if (!action) return state;
    let {increment} = state;
    switch (action.type) {
    case SoundConstants.changeSound:
        if (state.src === action.src) {
            increment++;
        } else {
            increment = 0;
        }
        return {
            ...state,
            src: action.src,
            isPlaying: false,
            increment
        };
    case SoundConstants.startSound:
        return {
            ...state,
            isPlaying: true
        };
    default:
        return state;
    }
}