import React from 'react';
import styled from 'styled-components';

import {Shape, ShapeTypes} from '../../utils/GameTypes';
import {AreaEffectDescriptor, MapDescriptor, MapParametersDescriptor} from '../MapTypes';
import {NeonBlossomMapSprites} from './NeonBlossomMapSprites';


const Container = styled.div`
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .3s;
    // The static background behind the map (EDIT_HERE)
    background: #281A47;

    .map {
        background-size: 100%;
        position: relative;
        // The URL of the asset (EDIT_HERE)
        background-image: url("/assets/game/maps/neon_blossom/Background.png");
        // The real dimensions of the map (EDIT_HERE)
        width: 1918px;
        height: 784px;
    }
`;

const Map: React.FC = (props) => (
    <Container className='map-frame'>
        <div className='map pixel-art'>
            {props.children}
        </div>
    </Container>
);

const constants: MapParametersDescriptor = {
    width: 1918,
    height: 784
};

const walls: Shape[] = [
    // Kokomart main building
    {
        start: {x: 150, y: 0},
        end: {x: 727, y: 387},
        shapeType: ShapeTypes.rectangle
    },
    // Kokomart left extrusion
    {
        start: {x: 150, y: 388},
        end: {x: 203, y: 415},
        shapeType: ShapeTypes.rectangle
    },
    // Kokomart right extrusion top part
    {
        start: {x: 524, y: 388},
        end: {x: 727, y: 401},
        shapeType: ShapeTypes.rectangle
    },
    // Kokomart right extrusion diagonal
    {
        start: {x: 526, y: 401},
        end: {x: 540, y: 415},
        shapeType: ShapeTypes.line
    },
    // Kokomart right extrusion bottom part
    {
        start: {x: 540, y: 402},
        end: {x: 727, y: 415},
        shapeType: ShapeTypes.rectangle
    },
    // Kokomart bushes
    {
        start: {x: 546, y: 416},
        end: {x: 697, y: 433},
        shapeType: ShapeTypes.rectangle
    },
    // Neon blossom main building
    {
        start: {x: 752, y: 252},
        end: {x: 899, y: 394},
        shapeType: ShapeTypes.rectangle
    },
    // Neon blossom fence
    {
        start: {x: 728, y: 360},
        end: {x: 911, y: 363},
        shapeType: ShapeTypes.rectangle
    },
    // Patterns inc left building
    {
        start: {x: 912, y: 0},
        end: {x: 1209, y: 407},
        shapeType: ShapeTypes.rectangle
    },
    // Patterns inc left extrusion
    {
        start: {x: 912, y: 408},
        end: {x: 957, y: 415},
        shapeType: ShapeTypes.rectangle
    },
    // Patterns inc main building
    {
        start: {x: 1210, y: 0},
        end: {x: 1861, y: 279},
        shapeType: ShapeTypes.rectangle
    },
    // Patterns inc main building left extrusion
    {
        start: {x: 1214, y: 280},
        end: {x: 1245, y: 287},
        shapeType: ShapeTypes.rectangle
    },
    // Patterns inc main building center extrusion
    {
        start: {x: 1496, y: 280},
        end: {x: 1513, y: 287},
        shapeType: ShapeTypes.rectangle
    },
    // Patterns inc main building right extrusion
    {
        start: {x: 1818, y: 280},
        end: {x: 1861, y: 287},
        shapeType: ShapeTypes.rectangle
    },
    // Patterns inc main building bushes
    {
        start: {x: 1522, y: 280},
        end: {x: 1813, y: 299},
        shapeType: ShapeTypes.rectangle
    }
];

const areas: AreaEffectDescriptor[] = [
    // Kokomart doors
    {
        area: {start: {x: 488, y: 768}, end: {x: 647, y: 824}},
        attributes: [],
        eventKey: 'kokomart_doors'
    },
    // Crosswalk
    {
        area: {start: {x: 1584, y: 1128}, end: {x: 1743, y: 1423}},
        attributes: [],
        eventKey: 'crossing'
    }
];

export const NeonBlossomMapObject: MapDescriptor = {
    map: Map,
    events: [],
    sprites: NeonBlossomMapSprites,
    areas,
    walls,
    constants
};