import {combineReducers} from 'redux';
import {reducer as burgerMenu} from 'redux-burger-menu';

import {AppReducer, AppState} from './AppReducer';
import {BackgroundReducer, BackgroundState} from './BackgroundReducer';
import {ModalReducer, ModalState} from './ModalReducer';
import {MusicReducer, MusicState} from './MusicReducer';
import {SoundReducer, SoundState} from './SoundReducer';

export interface RootState {
    app: AppState;
    background: BackgroundState;
    burgerMenu;
    modal: ModalState;
    music: MusicState;
    sound: SoundState;
}

// Reducer names should be normalized by cutting them down to the base object name
export const rootReducer = combineReducers<RootState>({
    app: AppReducer,
    background: BackgroundReducer,
    burgerMenu,
    modal: ModalReducer,
    music: MusicReducer,
    sound: SoundReducer
});