import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: inline-block;
    margin: 10px;
`;

type DeadLinkProps = {
    to?: string
}

export const DeadLink: React.FC<DeadLinkProps> = (props) => (
    <Container>
        {props.children}
    </Container>
);