import {EventDescriptor} from 'von-game/utils/EventTypes';
import {Coordinates, Shape} from 'von-game/utils/GameTypes';

export type SpriteDescriptor = {
    sprite: any,
    identifier: string,
    position: Coordinates,
    events: EventDescriptor[],
    hitbox?: Shape,
    movable?: boolean,
    movementEvent?: EventDescriptor
}

export const CHARACTER_IDENTIFIER = 'character';
export interface CharacterDescriptor extends SpriteDescriptor {
    identifier: typeof CHARACTER_IDENTIFIER;
    hitbox: Shape
}

export type SpriteProps = {
    identifier: string
}