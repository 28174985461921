import React from 'react';
import styled from 'styled-components';
import {endEvent} from 'von-game/GameContainer';

const Container = styled.div`
    width: 200px;
    height: 200px;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .spritesheet {
        position: absolute;
        background-image: url("/assets/PenguinFactoryLoader.png");
        background-size: 100%;
        animation: loadAnimation 1.8s steps(16) infinite;
        width: 3200px;
        height: 200px;
    }
`;

let totalImages = 0;
let loadedImages = 0;
let loadingTrigger = false;
let isLoading = false;

export const triggerLoading = () => {
    loadingTrigger = true;
};

const getBackgroundUrl = (el) => {
    let bg: string | null = '';
    if (el.currentStyle) {
        bg = el.currentStyle.backgroundImage;
    } else if (document.defaultView && document.defaultView.getComputedStyle) {
        bg = document.defaultView.getComputedStyle(el, '').backgroundImage;
    } else {
        bg = el.style.backgroundImage;
    }
    return bg ? bg.replace(/url\(['"]?(.*?)['"]?\)/i, '$1') : '';
};

const incrementLoaded = () => {
    loadedImages += 1;
};

const startLoading = () => {
    // If the trigger isn't set, ignore the call
    if (!loadingTrigger) {
        return;
    }
    isLoading = true;
    // Find all resources to load
    const elements = document.getElementsByClassName('pixel-art');
    totalImages = elements.length;
    for (let i = 0; i < elements.length; i++) {
        const imgUrl = getBackgroundUrl(elements.item(i));
        if (imgUrl === 'none') {
            incrementLoaded();
            continue;
        }
        // Create a temporary element for each image
        const image = document.createElement('img');
        image.src = imgUrl;
        // Once the temporary image is fully loaded, notify
        image.onload = incrementLoaded;
    }
};

const stopLoading = () => {
    loadingTrigger = false;
    isLoading = false;
    totalImages = 0;
    loadedImages = 0;
    endEvent();
};

const processLoading = () => {
    // If the loading hasn't started, start
    if (!isLoading) {
        startLoading();
        return;
    }
    // If all images have been loaded, stop
    if (loadedImages === totalImages) {
        stopLoading();
    }
};

export const LoadingScreenContainer: React.FC = () => {
    const step = () => {
        processLoading();
        window.requestAnimationFrame(() => {
            step();
        });
    };
    step();

    return (
        <Container className='loading-frame'>
            <div className='spritesheet pixel-art'/>
        </Container>
    );
};