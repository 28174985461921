import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import styled from 'styled-components';


type ContainerProps = {
    isSlideMenu?: boolean
}

const Container = styled.div<ContainerProps>`
    display: flex;

    a {
        text-decoration: none;
        font-size: 42px;
        transition: 0.2s;
        ${(props) => `color: ${props.isSlideMenu ? 'white' : 'rgb(38, 44, 120)'};`}
        ${(props) => `color: ${props.isSlideMenu ? 'white' : 'rgba(38, 44, 120, 0.9)'};`}
    }

    a.active {
        ${(props) => `color: ${props.isSlideMenu ? 'rgb(160, 160, 160)' : 'rgb(118, 6, 65)'};`}
        ${(props) => `color: ${props.isSlideMenu ? 'rgb(160, 160, 160, 0.9)' : 'rgba(118, 6, 65, 0.9)'};`}
    }
`;

type HeaderLinkProps = {
    to: string,
    isSlideMenu?: boolean
}

export const HeaderLink: React.FC<HeaderLinkProps> = (props) => {
    const location = useLocation().pathname;
    const isActive = location.startsWith(props.to);

    return (
        <Container isSlideMenu={props.isSlideMenu}>
            <Link to={props.to} className={`${isActive ? ' active' : ''}`}>
                {props.children}
            </Link>
        </Container>
    );
};