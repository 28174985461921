import {SpriteDescriptor} from 'von-game/sprites/SpriteTypes';
import {EventDescriptor} from 'von-game/utils/EventTypes';

import {Coordinates, Shape} from '../utils/GameTypes';

export const MAP_IDENTIFIER = 'map';

export type AreaEffectDescriptor = {
    area: Shape,
    attributes: string[],
    speedEffect?: (currentSpeed: number) => number,
    positionEffect?: (currentPosition: Coordinates) => Coordinates,
    eventKey?: string
}

export type MapParametersDescriptor = {
    /** The width expressed in pixels */
    width: number,
    /** The height expressed in pixels */
    height: number
}

export type MapDescriptor = {
    map: any,
    events: EventDescriptor[],
    sprites: SpriteDescriptor[],
    walls: Shape[],
    areas: AreaEffectDescriptor[],
    constants: MapParametersDescriptor
}