/**
 * Coordinates specify a position on the map on the x and y axis as well as the plane on which the
 * element is. The plane can be thought of as the z axis, but is not related to the z-index of the
 * asset.
 */
export type Coordinates = {
    x: number,
    y: number,
    /** The plane level for an element, mostlyused for display. */
    plane?: number
}

export const ShapeTypes = {
    /** Two points defining a rectangular space that no entity can move into. */
    rectangle: 'RECTANGLE',
    /** Two points defining a segment that no entity can cross. */
    line: 'LINE'
};

/**
 * A shape defines a geometrical element used for hitbox and event management.
 */
export type Shape = {
    start: Coordinates,
    end: Coordinates,
    /**
     * The plane level for a surface, mostly used for hitboxes.
     * Default is 100.
     * */
    plane?: number,
    shapeType?: string
}

export type GameStateDescriptor = {
    currentMap: string,
    currentCharacter: string,
    currentPosition: Coordinates,
    currentEventKeys: string[]
}