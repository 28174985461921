// Game constants (some constants are computed from the style to allow multiple screen sizes)
// NB: specific constants associated to the maps are stored in other files

/** The distance at which the character can trigger events */
export const EVENT_REACH = 20;

export const Directions = {
    DOWN: 'down',
    UP: 'up',
    LEFT: 'left',
    RIGHT: 'right'
};

export const DirectionKeys = {
    ArrowUp: 'up',
    ArrowLeft: 'left',
    ArrowRight: 'right',
    ArrowDown: 'down',
    KeyA: 'left',
    KeyD: 'right',
    KeyS: 'down',
    KeyW: 'up'
};

export const ActionKeys = {
    Enter: 'enter',
    Escape: 'escape',
    Space: 'space'
};