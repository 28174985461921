import {SongType} from 'redux/reducers/MusicReducer';

const START_MUSIC = 'START_MUSIC';
const STOP_MUSIC = 'STOP_MUSIC';
const CHANGE_MUSIC = 'CHANGE_MUSIC';

interface StartMusicDispatch {
    type: typeof START_MUSIC;
}
interface StopMusicDispatch {
    type: typeof STOP_MUSIC;
}
interface ChangeMusicDispatch {
    type: typeof CHANGE_MUSIC;
    singleSong?: SongType;
}

export const SONGS = {
    // Base tracks
    didYouKnow: {src: '/sounds/tracks/didYouKnow.mp3', title: 'Did you know'},
    reflectionless: {src: '/sounds/tracks/reflectionless.mp3', title: 'Reflectionless'},
    neonBlossom: {src: '/sounds/tracks/neonBlossom.mp3', title: 'Neon Blossom'},
    blue: {src: '/sounds/tracks/blue.mp3', title: 'Blue'},
    winter: {src: '/sounds/tracks/winter.mp3', title: 'Winter'},
    von: {src: '/sounds/tracks/von.mp3', title: 'VON'},
    // Bonus tracks
    here: {src: '/sounds/tracks/here.mp3', title: 'Here'},
    hypnosis: {src: '/sounds/tracks/hypnosis.mp3', title: 'Hypnosis'},
    // Other tracks
    mediumWarm: {src: '/sounds/tracks/mediumWarm.mp3', title: 'Medium-Warm'}
};

export const publicPlaylist: SongType[] = [
    SONGS.didYouKnow,
    SONGS.reflectionless,
    SONGS.neonBlossom,
    SONGS.blue
];

export const MusicConstants = {
    startMusic: START_MUSIC as typeof START_MUSIC,
    stopMusic: STOP_MUSIC as typeof STOP_MUSIC,
    changeMusic: CHANGE_MUSIC as typeof CHANGE_MUSIC
};

export type MusicDispatch =
    StartMusicDispatch |
    StopMusicDispatch |
    ChangeMusicDispatch;