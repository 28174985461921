import {ClassicLink, Page, Row, Video} from 'components';
import React, {useEffect} from 'react';
import {changeBackgroundImage, pauseMusic, playMusic} from 'redux/actions';
import {BACKGROUNDS} from 'redux/constants';

export const CommissionsPage: React.FC = () => {
    useEffect(() => {
        pauseMusic();
        return () => {
            playMusic();
        };
    }, []);

    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.LISA);
    }, []);

    return (
        <Page align='left'>
            <h1>Commissions</h1>
            <p>
                {'This is everything you need to know if you wish to commission me for some music!'}
            </p>
            <h2>Policy</h2>
            <p>
                <ul>
                    <li>
                        {`I have the right to decline any project that I would not feel capable of
                        achieving properly.`}
                    </li>
                    <li>
                        {'Payment in EURO via Stripe.'}
                    </li>
                    <li>
                        {'Prices may vary depending on the complexity of the composition / arrangement.'}
                    </li>
                    <li>
                        {'A very short deadline may result in a rush fee.'}
                    </li>
                    <li>
                        {`50% upfront pay is available upon request, otherwise full upfront pay is
                        required.`}
                    </li>
                    <li>
                        {`Please understand that my services will not be fully refundable once I've
                        started. Upon the sketch draft process, a 50% refund is possible. Any
                        further progress will be non refundable.`}
                    </li>
                    <li>
                        {`If you want to cancel the order after the final product is finished, 50%
                        of the remaining fee must still be paid as a penalty. Furthermore, I will
                        retain the rights on the final product.`}
                    </li>
                    <li>
                        {`If payment for any of the services I provide is late, a 10% additional
                        fee will be added for every month past due.`}
                    </li>
                    <li>
                        {'3 big revisions are available before additional fees.'}
                    </li>
                    <li>
                        {`I keep the commercial rights to the music I produce, unless stated
                        otherwise in a written contract.`}
                    </li>
                    <li>
                        {`I reserve the rights to publish any track I produce on Youtube for
                        portfolio purposes, unless stated otherwise in a written contract.`}
                    </li>
                </ul>
            </p>
            <h2>Services and Pricing</h2>
            <ul>
                <li><b>{'Background / Ambient music & Ost (250€/min)'}</b></li>
            </ul>
            <Row centered={false}>
                <Video small name='Cloudy Hollow' shortUrl='NSpN18l4-vo'></Video>
                <Video small name='Ethereal Wind' shortUrl='sVU0fdOz7kk'></Video>
                <Video small name='Title Screen' shortUrl='_FRB0GesJow'></Video>
            </Row>
            <ul>
                <li><b>{'Arrangements / Instrumentals (250€/min, if piano solo 200€/min)'}</b></li>
            </ul>
            <Row centered={false}>
                <Video small name='Love Space' shortUrl='d3EOerHk47U'></Video>
                <Video small name='Blind to You' shortUrl='wWwcGrO9Ljo'></Video>
                <Video small name='Weight of the World' shortUrl='dIGP72gk5Qo'></Video>
                <Video small name='Tides' shortUrl='baQrdfSHrII'></Video>
                <Video small name='Spiral Tones' shortUrl='BsBSxyBdSts'></Video>
            </Row>
            <ul>
                <li><b>{'Original instrumentals for singles, albums and more (Please contact me)'}</b></li>
            </ul>
            <Row centered={false}>
                <Video small name='End of a Life' shortUrl='BXB26PzV31k'></Video>
                <Video small name='Deja Vu' shortUrl='pxtGwAtBSjQ'></Video>
                <Video small name='52 Hearts' shortUrl='JNsYaefCe-Y'></Video>
            </Row>
            <ul>
                <li><b>{'Video Game Music / Film music (Please contact me)'}</b></li>
            </ul>
            <Row centered={false}>
                <Video small name='Mythical encounter' shortUrl='xPChMPMCxAQ'></Video>
                <Video small name='Von' shortUrl='AztZcoLdwNI'></Video>
                <Video small name='Memories of treasured times' shortUrl='MF0ARwzFrd0'></Video>
            </Row>
            <h2>Additional note</h2>
            <p>
                {`I’m mostly comfortable in the following styles / genres : orchestral, ambient,
                soft pop, beats and acoustic instrumentals but I’m always interested in new
                challenges, feel free to give me extra details so we can create your own
                original song / arrangement together!`}
            </p>
            <p>
                {'For more examples, visit: '}
                <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={'https://www.youtube.com/c/SomeGuyWhoPlaysPiano'}
                >Pretty Patterns</a>
            </p>
            <ClassicLink to={'/home'}>BACK</ClassicLink>
        </Page>
    );
};