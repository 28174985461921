const START_APP = 'START_APP';

interface StartAppDispatch {
    type: typeof START_APP;
}

export const AppConstants = {
    startApp: START_APP as typeof START_APP
};

export type AppDispatch =
    StartAppDispatch;