import {ClassicLink, DeadLink, Page, Row, Spring} from 'components';
import React, {useEffect} from 'react';
import {changeBackgroundImage} from 'redux/actions';
import {BACKGROUNDS} from 'redux/constants';

export const Chapter1Extra: React.FC = () => {
    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.NEON_BLOSSOM);
    }, []);

    return (
        <Page align='left'>
            <h1>Wednesday October 1st, 2053 - 7:48 PM</h1>
            <p>
                {`The safe finally unlocked with a small clicking sound and I opened
                the door to discover its meager contents: a few sheets of paper and
                a Smart Reel, a new kind of smartphone that became popular recently
                thanks to its ability to play cassette tapes. Altogether, the safe
                looked ridiculously empty considering its size.`}
            </p>
            <p>
                {`But my disappointment only lasted a few moments, until I started taking
                a look at the papers. Most of them were also official-looking documents
                describing the Patterns technology, but in much more detail than anything
                I had seen before. More than anything, several pages were detailing
                the underlying risks and even some results from tests that had been
                conducted on animals.`}
            </p>
            <p>
                {`As I was trying to understand all the technical jargon written, a few
                smaller pages fell from the bundle of documents I was holding. I quickly
                picked them up and started reading what seemed to be the last missing
                entries from the notebook I had found previously.`}
            </p>
            <p>
                {`With every line I read, my heart started beating faster and faster. These
                entries stated that after my father and his team were contacted by
                the government regarding his research, the focus started to shift towards
                the possibility of controlling someone's memories and emotions instead of
                simply storing them. As I kept reading and thought things couldn't get worse,
                the final entry made me freeze completely: "They're onto me. I need to finish
                all the preparations before it's too late".`}
            </p>
            <p>
                {`An infinite number of scenarios popped into my mind, concerning what
                happened to my father, what the government managed to do with the
                Patterns technology and how everything led to the situation we were currently
                in. After a few minutes that felt like hours, some noise outside the house
                brought me back to my senses: a car had stopped nearby and several people had
                stepped outside. In my current state, the first thing I could think of was that
                someone knew I was here and they were coming for me.`}
            </p>
            <p>
                {`Trying to fight the waves of panic and anguish, I hurriedly grabbed all the
                papers lying around me as well as the smartphone and stuffed them in
                my bag before rushing down the stairs. Deciding not to take any risks, I ran to
                the back of the house and exited through the veranda into a small garden. Not even
                stopping to check whether someone had come inside the house after me, I
                climbed over the fence into a small alley and started running as fast as I could
                without ever looking behind.`}
            </p>
            <p>{`I kept going for what seemed like an eternity, finally stopping completely out
                of breath and nearly choking while gasping for air.`}</p>
            <Row centered={false}>
                <ClassicLink to={'/von'}>BACK</ClassicLink>
                <Spring />
                <DeadLink to={'/von/3'}>Next (Coming soon)</DeadLink>
            </Row>
        </Page>
    );
};