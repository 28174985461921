import {Howl} from 'howler';
import React from 'react';
import styled from 'styled-components';
import {PlayTypes} from 'von-game/utils/EventTypes';
import {SoundDescriptor, SoundPack} from 'von-game/utils/SoundTypes';

const Container = styled.div`
    display: none;
`;

let currentSoundPack: SoundPack | undefined;
let currentSound: SoundDescriptor | undefined;
let isLocked = false;

const switchTrack = (identifier: string) => {
    if (!currentSoundPack || currentSoundPack.activeSound === identifier) {
        return;
    }
    currentSoundPack.sounds.forEach((sound) => {
        if (!sound.howl) {
            return;
        }
        if (sound.identifier === identifier) {
            sound.howl.fade(0, 1, 500);
        } else {
            sound.howl.fade(1, 0, 500);
        }
    });
};

const stopSound = (sound: SoundDescriptor) => {
    if (!sound || !sound.howl) {
        return;
    }
    const {howl} = sound;
    howl.fade(howl.volume(), 0, 500);
    setTimeout(() => {
        howl.stop();
    }, 500);
};

const stopSoundPack = () => {
    if (!currentSoundPack) {
        return;
    }
    currentSoundPack.sounds.forEach((sound) => stopSound(sound));
    currentSoundPack.isPlaying = false;
};

const stopAll = () => {
    stopSoundPack();
    if (currentSound) {
        stopSound(currentSound);
    }
};

const updateLoadingStatus = () => {
    if (!currentSoundPack || currentSoundPack.isPlaying) {
        return;
    }
    // If all sounds are loaded, play
    const {sounds, activeSound} = currentSoundPack;
    if (!sounds.find((sound) => !sound.loaded)) {
        currentSoundPack.isPlaying = true;
        sounds.forEach((sound) => {
            if (sound.howl) {
                sound.howl.play();
                if (activeSound === sound.identifier) {
                    sound.howl.fade(0, 1, 500);
                }
            }
        });
    }
};

const getSound: (identifier: string) => SoundDescriptor | undefined = (identifier) => currentSoundPack && currentSoundPack.sounds.find((sound) => sound.identifier === identifier);

const startSoundPack = (soundPack: SoundPack) => {
    // Stop the current sound pack
    stopAll();
    // Start the new sound pack
    currentSoundPack = {...soundPack};
    const {sounds} = currentSoundPack;
    sounds.forEach((sound) => {
        const howl = new Howl({
            src: [sound.src],
            loop: true, // Always loop sound packs
            volume: 0
        });
        sound.howl = howl;
        if (howl.state() === 'loaded') {
            sound.loaded = true;
        } else {
            howl.on('load', () => {
                sound.loaded = true;
                updateLoadingStatus();
            });
        }
    });
    updateLoadingStatus();
};

export const playSoundPack = (soundPack: SoundPack, playType: string = PlayTypes.normal) => {
    switch (playType) {
        case PlayTypes.replace:
            // Fully replace the current sound pack
            startSoundPack(soundPack);
            break;
            // N.B: For all other play types, if the sound pack is not playing nothing will happen
        case PlayTypes.mute:
            // Mute the specified track
            const {howl: howlToMute = undefined} = getSound(soundPack.activeSound) || {};
            if (howlToMute) {
                howlToMute.fade(howlToMute.volume(), 0, 500);
            }
            break;
        case PlayTypes.unmute:
            // Unmute the specified track or start the sound pack
            const {howl: howlToUnmute = undefined} = getSound(soundPack.activeSound) || {};
            if (howlToUnmute) {
                howlToUnmute.fade(howlToUnmute.volume(), 1, 500);
            } else {
                startSoundPack(soundPack);
            }
            break;
        case PlayTypes.switch:
            // Change the current track or start the sound pack
            if (getSound(soundPack.activeSound)) {
                switchTrack(soundPack.activeSound);
            } else {
                startSoundPack(soundPack);
            }
            break;
        default:
            // Should never happen, ignore
            break;
    }
};

const buildSoundPack: (sound: SoundDescriptor) => SoundPack = (sound) => ({
    activeSound: sound.identifier,
    name: sound.identifier,
    sounds: [sound]
});

const startSound = (sound: SoundDescriptor) => {
    const howl = new Howl({
        src: [sound.src],
        html5: true,
        volume: 0,
        loop: sound.loop,
        onplayerror: () => {
            if (!isLocked) {
                isLocked = true;
                howl.once('unlock', () => {
                    howl.play();
                });
            }
        },
        onload: () => {
            howl.play();
            howl.fade(0, 1, 500);
        }
    });
    sound.howl = howl;
};

export const playSound = (newSound: SoundDescriptor, playType: string = PlayTypes.normal) => {
    switch (playType) {
        case PlayTypes.replace:
            // Stop all sounds and start the new sound
            stopAll();
            currentSound = {...newSound};
            startSound(currentSound);
            break;
        case PlayTypes.mute:
        case PlayTypes.unmute:
        case PlayTypes.switch:
            // Should only be used with sound packs, try to merge
            playSoundPack(buildSoundPack(newSound), playType);
            break;
        default:
            // Simply play the sound
            startSound(newSound);
            break;
    }
};

export const SoundContainer: React.FC = () => (
    <Container className='sound-frame' />
);