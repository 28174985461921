import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    max-width: 48%;
    min-width: 260px;
    text-align: center;
    padding: 10px;
    background-image: url(${`${process.env.PUBLIC_URL}/black.jpg`});
    opacity: 0.8;
    flex: 1 1 auto;
    border-radius: 10px;
`;

export const HalfPage: React.FC = (props) => (
    <Container>
        {props.children}
    </Container>
);