export enum ModalType {
    ALERT = 'alert',
    WARNING = 'warning',
    ERROR = 'error',
    CONFIRM = 'confirm'
}

export type ModalProps = {
    title: string,
    text: string,
    type: ModalType,
    buttonText?: string,
    onConfirm?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}