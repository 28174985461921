import {HeaderLink, Menu} from 'components';
import React from 'react';


export const SlideMenu: React.FC = () => (
    <Menu disableAutoFocus>
        <HeaderLink isSlideMenu={true} to={'/home'}>HOME</HeaderLink>
        <HeaderLink isSlideMenu={true} to={'/bio'}>BIOGRAPHY</HeaderLink>
        <HeaderLink isSlideMenu={true} to={'/portfolio'}>PORTFOLIO</HeaderLink>
        <HeaderLink isSlideMenu={true} to={'/social'}>SOCIALS</HeaderLink>
        <HeaderLink isSlideMenu={true} to={'/commissions'}>COMMISSIONS</HeaderLink>
    </Menu>
);