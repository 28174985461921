import {ClassicLink, Column, DeadLink, Image, Page, PageSeparator, Row} from 'components';
import React, {useEffect} from 'react';
import {changeBackgroundImage} from 'redux/actions';
import {BACKGROUNDS, PICTURES} from 'redux/constants';

export const VonPage: React.FC = () => {
    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.NEON_BLOSSOM);
    }, []);

    return (
        <Page>
            <h1>VON</h1>
            <Row centered={false}>
                <Column width='45%'>
                    <Image src={PICTURES.LISA_PORTRAIT} />
                </Column>
                <Column width='45%'>
                    <div>Name: Lisa</div>
                    <div>Birth date: XX/XX/2029</div>
                    <div>Height: 158cm</div>
                    <div>Occupation: </div>
                    <PageSeparator />
                    <div>
                        {`The subject's implication seems to be too recent to retrieve relevant
                        data. As for the others, all links will be removed. Observation status:
                        passive.`}
                    </div>
                </Column>
            </Row>
            <Column>
                <ClassicLink to={'/von/0'}>PROLOGUE</ClassicLink>
                <ClassicLink to={'/von/1'}>CHAPTER 1</ClassicLink>
                <DeadLink>CHAPTER 2 (Coming soon)</DeadLink>
                <DeadLink>CHAPTER 3 (Coming soon)</DeadLink>
                <DeadLink>CHAPTER 4 (Coming soon)</DeadLink>
                <DeadLink>CHAPTER 5 (Coming soon)</DeadLink>
                <DeadLink>CHAPTER 6 (Coming soon)</DeadLink>
            </Column>
            <ClassicLink to={'/home'}>BACK</ClassicLink>
        </Page>
    );
};