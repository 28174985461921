import {CHARACTER_IDENTIFIER, CharacterDescriptor} from '../SpriteTypes';
import {BunSprite} from './BunSprite';
import {DiegoSprite} from './DiegoSprite';
import {GabiSprite} from './GabiSprite';
import {LisaSprite} from './LisaSprite';
import {PenguinSprite} from './PenguinSprite';
import {TatoSprite} from './TatoSprite';

const Penguin: CharacterDescriptor = {
    identifier: CHARACTER_IDENTIFIER,
    sprite: PenguinSprite,
    hitbox: {
        start: {x: 20, y: 50},
        end: {x: 44, y: 59},
        plane: 100
    },
    position: {x: 50, y: 60, plane: 100},
    events: []
};

const Lisa: CharacterDescriptor = {
    identifier: CHARACTER_IDENTIFIER,
    sprite: LisaSprite,
    hitbox: {
        start: {x: 8, y: 26},
        end: {x: 23, y: 31},
        plane: 100
    },
    position: {x: 50, y: 60, plane: 100},
    events: []
};

const Diego: CharacterDescriptor = {
    identifier: CHARACTER_IDENTIFIER,
    sprite: DiegoSprite,
    hitbox: {
        start: {x: 8, y: 26},
        end: {x: 23, y: 31},
        plane: 100
    },
    position: {x: 50, y: 60, plane: 100},
    events: []
};

const Bun: CharacterDescriptor = {
    identifier: CHARACTER_IDENTIFIER,
    sprite: BunSprite,
    hitbox: {
        start: {x: 8, y: 26},
        end: {x: 23, y: 31},
        plane: 100
    },
    position: {x: 50, y: 60, plane: 100},
    events: []
};

const Tato: CharacterDescriptor = {
    identifier: CHARACTER_IDENTIFIER,
    sprite: TatoSprite,
    hitbox: {
        start: {x: 8, y: 26},
        end: {x: 23, y: 31},
        plane: 100
    },
    position: {x: 50, y: 60, plane: 100},
    events: []
};

const Gabi: CharacterDescriptor = {
    identifier: CHARACTER_IDENTIFIER,
    sprite: GabiSprite,
    hitbox: {
        start: {x: 8, y: 26},
        end: {x: 23, y: 31},
        plane: 100
    },
    position: {x: 50, y: 60, plane: 100},
    events: []
};

/**
 * All the characters (player only) used in the game
 */
export const CHARACTERS: { [key: string]: CharacterDescriptor } = {
    PENGUIN: Penguin,
    LISA: Lisa,
    DIEGO: Diego,
    BUN: Bun,
    TATO: Tato,
    GABI: Gabi
};