import React from 'react';
import styled from 'styled-components';
import {ChoiceDescriptor, DialogDescriptor} from 'von-game/utils/DialogTypes';

import {endEvent} from '../GameContainer';
import {ActionKeys, DirectionKeys, Directions} from '../utils/GameConstants';
import {resetZoom, zoom} from './6_MapContainer';

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;

    .dialog {
        z-index: 1002;
        font-size: 24px;
        line-height: 1.6em;
        word-spacing: 0.2em;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;

        .text {
            background-color: rgba(0, 0, 0, 0);
            transition: background-color .6s;
            height: 150px;
            max-height: 150px;
            padding: 6px 16px;
            margin: 0 20px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 20px 20px 0 0;
        }

        .name {
            background-color: rgba(0, 0, 0, 0);
            transition: background-color .6s;
            padding: 0 10px;
            min-width: 100px;
            position: absolute;
            bottom: 162px;
            left: 50px;
            border-radius: 10px 10px 0 0;
        }

        .choices {
            background-color: rgba(0, 0, 0, 0);
            transition: background-color .6s;
            padding: 16px;
            margin: 0 20px;
            color: white;
            position: absolute;
            top: -100%;
            right: 0;

            .selected {
                color: black;
                background: white;
            }
        }
    }

    .left-sprite, .right-sprite {
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        width: 400px;
        height: 600px;
        overflow: hidden;
        opacity: 0;
    }

    .text span {
        color: white;
        opacity: 0;
        transition: opacity .1s;
    }

    .text span.revealed {
        opacity: 1;
    }
`;

type DialogLineCharacterDescriptor = {
    span: any,
    isSpace?: boolean,
    delayAfter: number
}

let container = document.querySelector<HTMLDivElement>('.dialog');
let textElement = document.querySelector<HTMLDivElement>('.text');
let nameElement = document.querySelector<HTMLDivElement>('.name');
let choiceElement = document.querySelector<HTMLDivElement>('.choices');
let leftSpriteElement = document.querySelector<HTMLDivElement>('.left-sprite');
let rightSpriteElement = document.querySelector<HTMLDivElement>('.right-sprite');

// Observer used to check if the selectors identify valid elements
const observer = new MutationObserver(((mutations, me) => {
    container = document.querySelector<HTMLDivElement>('.dialog');
    textElement = document.querySelector<HTMLDivElement>('.text');
    nameElement = document.querySelector<HTMLDivElement>('.name');
    choiceElement = document.querySelector<HTMLDivElement>('.choices');
    leftSpriteElement = document.querySelector<HTMLDivElement>('.left-sprite');
    rightSpriteElement = document.querySelector<HTMLDivElement>('.right-sprite');
    if (container && textElement && nameElement && choiceElement
        && leftSpriteElement && rightSpriteElement) {
        me.disconnect();
    }
}));

observer.observe(document, {
    childList: true,
    subtree: true
});

let currentDiscussion: DialogDescriptor[] = [];
let currentDialog: DialogDescriptor | undefined;
let currentChoice: ChoiceDescriptor | undefined;
let choiceIndex = 0;
let isShowingChoices = false;
let isDiscussionStarted = false;
let isDialogOngoing = false;
let isDialogFinished = false;
let isChoosing = false;
let characters: DialogLineCharacterDescriptor[] = [];
let currentAction = '';
let currentDirection = '';

export const displayDiscussion = (discussion: DialogDescriptor[], choice?: ChoiceDescriptor) => {
    zoom();
    currentDiscussion = [...discussion];
    if (choice) {
        currentChoice = {...choice};
    }
    isDiscussionStarted = true;
    if (textElement) {
        textElement.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    }
};

const displayDecorations = () => {
    if (!currentDialog) {
        return;
    }
    // Display the name
    if (nameElement && currentDialog.name) {
        const span = document.createElement('span');
        span.textContent = currentDialog.name;
        nameElement.appendChild(span);
        nameElement.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    }

    // Display the sprites
    if (leftSpriteElement && currentDialog.leftSprite) {
        leftSpriteElement.style.backgroundImage = `url("${currentDialog.leftSprite.path}")`;
        leftSpriteElement.style.top = `${currentDialog.leftSprite.position.y}px`;
        leftSpriteElement.style.left = `${currentDialog.leftSprite.position.x}px`;
        leftSpriteElement.style.opacity = '1';
    }
};

const hideDecorations = () => {
    // Hide the name
    if (nameElement) {
        nameElement.innerHTML = '';
        nameElement.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    }

    // Display the sprites
    if (leftSpriteElement) {
        leftSpriteElement.style.backgroundImage = 'unset';
        leftSpriteElement.style.opacity = '0';
    }
};

const buildCharacterList = () => {
    if (!currentDialog) {
        return;
    }

    hideDecorations();
    displayDecorations();

    // Clear the list first
    characters = [];
    // Add to the list from the current dialog
    currentDialog.lines.forEach((line, index) => {
        if (!line.stick && index < currentDialog!.lines.length - 1) {
            line.text += ' '; // Add a space between lines
        }

        line.text.split('').forEach((character) => {
            if (character === '\n') {
                const br = document.createElement('br');
                if (textElement) {
                    textElement.appendChild(br);
                }
                return;
            }
            const span = document.createElement('span');
            span.textContent = character;
            if (line.color) {
                span.style.color = line.color;
            }
            if (line.style) {
                span.style.fontStyle = line.style;
            }
            if (line.weight) {
                span.style.fontWeight = line.weight;
            }
            if (textElement) {
                textElement.appendChild(span);
            }
            characters.push({
                span,
                isSpace: character === ' ' && !line.pause,
                delayAfter: line.speed
            });
        });
    });
};

const revealOneCharacter = (list: DialogLineCharacterDescriptor[]) => {
    // If the current dialog is already finished, no need to display more characters
    if (isDialogFinished) {
        return;
    }

    // If an action is triggered during a dialog, skip and display everything
    if (currentAction) {
        currentAction = '';
        list.forEach((character) => {
            character.span.classList.add('revealed');
        });
        // Clear the character list once everything has been displayed
        characters = [];
        isDialogFinished = true;
        return;
    }

    // Display one character, pause and call the method with the remaining characters
    const next = list.splice(0, 1)[0];
    next.span.classList.add('revealed');
    const delay = next.isSpace ? 0 : next.delayAfter;

    if (list.length > 0) {
        setTimeout(() => {
            revealOneCharacter(list);
        }, delay);
    } else {
        isDialogFinished = true;
    }
};

// Clear everything from the text box to prepare for the next dialog
const eraseCurrentCharacters = () => {
    currentDialog = undefined;
    isDialogOngoing = false;
    isDialogFinished = false;
    if (textElement) {
        textElement.innerHTML = '';
    }
};

const buildChoices = () => {
    if (!currentChoice) {
        return;
    }
    currentChoice.choices.forEach((choice, index) => {
        const choiceDiv = document.createElement('div');
        const choiceText = document.createTextNode(choice);
        choiceDiv.appendChild(choiceText);
        if (choiceElement) {
            choiceElement.appendChild(choiceDiv);
        }
    });
};

const processChoiceDisplay = () => {
    if (!currentChoice) {
        return;
    }
    if (!isShowingChoices) {
        isShowingChoices = true;
        if (choiceElement) {
            choiceElement.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
        }
        buildChoices();
    }

    // Update the index
    if (choiceIndex < 0) {
        choiceIndex = 0;
    }
    if (choiceIndex >= currentChoice.choices.length) {
        choiceIndex = currentChoice.choices.length - 1;
    }

    // Update the display
    if (choiceElement) {
        const choiceTexts = choiceElement.children;
        for (let i = 0; i < choiceTexts.length; i++) {
            const choiceText = choiceTexts[i];
            if (choiceIndex === i) {
                choiceText.classList.add('selected');
            } else {
                choiceText.classList.remove('selected');
            }
        }
    }
};

const processChoiceInputs = () => {
    // For a directional input, change the selected choice
    if (currentDirection === Directions.RIGHT || currentDirection === Directions.DOWN) {
        choiceIndex += 1;
        currentDirection = '';
        return;
    }
    if (currentDirection === Directions.LEFT || currentDirection === Directions.UP) {
        choiceIndex -= 1;
        currentDirection = '';
        return;
    }
    // For an action input, validate the current choice
    if (currentAction) {
        currentAction = '';
        eraseCurrentCharacters();
        currentChoice = undefined;
        isChoosing = false;
        isShowingChoices = false;
        if (textElement) {
            textElement.style.backgroundColor = 'rgba(0, 0, 0, 0)';
        }
        if (choiceElement) {
            choiceElement.style.backgroundColor = 'rgba(0, 0, 0, 0)';
            choiceElement.innerHTML = '';
        }
        isDiscussionStarted = false;
        endEvent(choiceIndex);
        choiceIndex = -1;
    }
};

// Process the current dialog
const processDialog = () => {
    if (isDialogFinished) {
        // If this dialog is a choice, only process the player inputs
        if (isChoosing) {
            processChoiceDisplay();
            processChoiceInputs();
            return;
        }
        // Wait until an action key is pressed before setting the next dialog
        if (currentAction) {
            currentAction = '';
            eraseCurrentCharacters();
        }
        return;
    }
    if (isDialogOngoing) {
        return;
    }
    isDialogOngoing = true;
    buildCharacterList();
    setTimeout(() => {
        revealOneCharacter(characters);
    }, 200);
};

const processChoice = () => {
    // If the discussion has no choice, end the discussion
    if (!currentChoice) {
        if (textElement) {
            textElement.style.backgroundColor = 'rgba(0, 0, 0, 0)';
        }
        isDiscussionStarted = false;
        resetZoom();
        hideDecorations();
        endEvent();
        return;
    }
    isChoosing = true;
    currentDialog = currentChoice;
};

// Process the current discussion
const processDiscussion = () => {
    // If the current dialog is set, keep processing it
    if (currentDialog) {
        processDialog();
        return;
    }
    // If there are still dialogs in the list, set the next dialog
    if (currentDiscussion[0]) {
        currentDialog = currentDiscussion.shift();
        return;
    }
    // Once all dialogs have been processed, process the choice
    if (isDiscussionStarted) {
        processChoice();
    }
};

export const DialogContainer: React.FC = () => {
    document.addEventListener('keydown', (e) => {
        if (!isDiscussionStarted) {
            currentAction = '';
            currentDirection = '';
            return;
        }
        const action = ActionKeys[e.code];
        if (action && !currentAction) {
            currentAction = action;
            return;
        }
        const direction = DirectionKeys[e.code];
        if (direction && !currentDirection) {
            currentDirection = direction;
        }
    });

    const step = () => {
        processDiscussion();
        window.requestAnimationFrame(() => {
            step();
        });
    };
    step();

    return (
        <Container className='dialog-frame'>
            <div className='dialog'>
                <div className='text' />
                <div className='name' />
                <div className='choices' />
            </div>
            <div className='left-sprite' />
            <div className='right-sprite' />
        </Container>
    );
};