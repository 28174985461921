import {Spring} from 'components';
import React from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

type FooterProps = {
    hideHeader: boolean
}

const Container = styled.footer<FooterProps>`
    flex: 0 1 auto;
    padding: 10px 20px;
    ${(props) => `display: ${props.hideHeader ? 'none' : 'flex'};`}
    flex-flow: row;
    background: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.6);
    font-size: 1.2rem;
    color: black;

    a.white {
        color: white;
    }
`;

export const Footer: React.FC = () => {
    const location = useLocation().pathname;
    const hideHeader = location === '/home' || location === '/test';

    return (
        <Container hideHeader={hideHeader}>
            <a
                className='white'
                target='_blank'
                rel='noopener noreferrer'
                href='https://twitter.com/Lilblueorchid'
            >
            Art by @lilblueorchid / Claire Sun
            </a>
            <Spring />
            <div>
            ©Penguin Factory
            </div>
        </Container>
    );
};