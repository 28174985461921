import React, {useEffect} from 'react';
import {changeBackgroundImage} from 'redux/actions';
import {BACKGROUNDS} from 'redux/constants';
import {GameContainer} from 'von-game';

export const GamePage: React.FC = () => {
    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.STREET2);
    }, []);

    return (
        <GameContainer />
    );
};