import {MusicConstants, MusicDispatch} from 'redux/constants';
import {SongType} from 'redux/reducers/MusicReducer';
import {store} from 'redux/store';

const startMusic: () => MusicDispatch = () => ({
    type: MusicConstants.startMusic
});

export const playMusic = () => store.dispatch(startMusic());

const stopMusic: () => MusicDispatch = () => ({
    type: MusicConstants.stopMusic
});

export const pauseMusic = () => store.dispatch(stopMusic());

const changeMusic: (singleSong?: SongType) => MusicDispatch = (singleSong) => ({
    type: MusicConstants.changeMusic,
    singleSong
});

export const changeSong = (singleSong?: SongType) => {
    store.dispatch(changeMusic(singleSong));
};