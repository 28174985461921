import {ModalProps} from 'components/modals/ModalProps';

const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

interface OpenModalDispatch {
    type: typeof OPEN_MODAL;
    modal: ModalProps
}
interface CloseModalDispatch {
    type: typeof CLOSE_MODAL;
}

export const ModalConstants = {
    openModal: OPEN_MODAL as typeof OPEN_MODAL,
    closeModal: CLOSE_MODAL as typeof CLOSE_MODAL
};

export type ModalDispatch =
    OpenModalDispatch |
    CloseModalDispatch;