import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {RootState} from 'redux/reducers';
import styled from 'styled-components';

type ContainerProps = {
    small?: boolean
}

const Container = styled.div<ContainerProps>`
    background-color: black;
    border-radius: 10px;
    ${(props) => `width: ${props.small ? '180' : '420'}px;`}
    ${(props) => `height: ${props.small ? '105' : '315'}px;`}
    margin: 20px auto;

    @media screen and (max-device-width: 600px) {
        ${(props) => `width: ${props.small ? '180px' : '95%'};`}
        ${(props) => `height: ${props.small ? '105' : '315'}px;`}
    }

    iframe {
        border-radius: 10px;
        max-width: 100%;
    }
`;

const mapStateToProps = (state: RootState) => ({
    isMusicPlaying: state.music.isPlaying
});

const connector = connect(mapStateToProps);

type VideoConnectedProps = ConnectedProps<typeof connector>;

type VideoProps = VideoConnectedProps & {
    name: string,
    shortUrl: string,
    small?: boolean
}

const Video: React.FC<VideoProps> = (props) => (
    <Container small={props.small}>
        <iframe
            title={props.name}
            src={`https://www.youtube.com/embed/${props.shortUrl}?rel=0`}
            width={props.small ? '180' : '420'}
            height={props.small ? '105' : '315'}
            allowFullScreen
            frameBorder={0}
        >
        </iframe>
        {props.children}
    </Container>
);

export const ConnectedVideo = connector(Video);