import {BackgroundContainer, Footer, Header, Routes} from 'components';
import {ModalContainer} from 'components/modals';
import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {BACKGROUNDS} from 'redux/constants';
import styled from 'styled-components';

type ImageProps = {
    imageUrls: string;
}

const Container = styled.div<ImageProps>`
    position: relative;
    height: 100%;
    display: flex;
    flex-flow: column;
    color: white;
    font-size: 1.1rem;

    ::after{
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        z-index: -1;
        ${(props) => `content:${props.imageUrls};`}
    }
`;

const Main = styled.main`
    display: flex;
    flex: 1 1 auto;
    width: 90%;
    min-width: 320px;
    margin: 0 auto;
    padding: 20px 0;

    @media screen and (max-device-width: 600px) {
        width: 100%;
    }
`;

const mapImages: (images: { [key: string]: string }) => string = (images) => {
    let result = '';
    for (const value of Object.values(images)) {
        result += ` url(${value})`;
    }
    return result;
};

export const App: React.FC = () => (
    <Container imageUrls={mapImages(BACKGROUNDS)}>
        <BrowserRouter>
            <React.Fragment>
                <ModalContainer />
                <BackgroundContainer />
                <Header />
                <Main>
                    <Routes />
                </Main>
                <Footer />
            </React.Fragment>
        </BrowserRouter>
    </Container>
);