import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: inline-block;
    margin: 10px;
`;

type ClassicLinkProps = {
    to: string
}

export const ClassicLink: React.FC<ClassicLinkProps> = (props) => (
    <Container>
        <Link to={props.to} style={{color: 'white', textDecoration: 'none', fontSize: 20}}>
            {props.children}
        </Link>
    </Container>
);