import React from 'react';
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    height: 56.8px;
`;

type HomeLinkProps = {
    to: string
}

export const HomeLink: React.FC<HomeLinkProps> = (props) => (
    <Container>
        <Link to={props.to} className='home-link'>
            {props.children}
        </Link>
    </Container>
);