import {MusicConstants, MusicDispatch, publicPlaylist} from 'redux/constants';

export type SongType = {
    src: string,
    title?: string,
    artist?: string
}

function shuffleArray(array: SongType[]) {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
}

export class MusicState {
    isPlaying: boolean = false;
    playlist: SongType[] = shuffleArray(publicPlaylist);
    singleSong?: SongType;
}

export function MusicReducer(
    state = new MusicState(),
    action: MusicDispatch
): MusicState {
    if (!action) return state;
    switch (action.type) {
        case MusicConstants.startMusic:
            return {
                ...state,
                isPlaying: true
            };
        case MusicConstants.stopMusic:
            return {
                ...state,
                isPlaying: false
            };
        case MusicConstants.changeMusic:
            return {
                ...state,
                singleSong: action.singleSong,
                isPlaying: false
            };
        default:
            return state;
    }
}