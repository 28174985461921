import {ChoiceDescriptor, DialogDescriptor} from './DialogTypes';
import {Coordinates, Shape} from './GameTypes';
import {SoundDescriptor, SoundPack} from './SoundTypes';

interface BaseEvent {
    type: string;
    hitbox?: Shape;
    /** true for player triggered events, false for automatic events (ex: map changes) */
    manual?: boolean;
    /** Event keys that, if not owned, will ignore the event */
    requiredKeys: string[];
    /** Event keys that, if owned, will ignore the event */
    forbiddenKeys: string[];
    /** If the event key is part of the forbidden keys, the event won't be repeatable */
    eventKey?: string;
    /**
     * If defined, one of these events will play once the current one is finished
     * (after checking the keys).
     * Multiple events can be defined for choice type events.
     */
    nextEvents: EventDescriptor[];
}

export type AutomaticManipulation = {
    identifier: string,
    checkEnd?: (position: Coordinates, counter: number) => boolean,
    positionDefinition: (position: Coordinates, counter: number, eventKeys: string[], element: any) => Coordinates,
    walking?: boolean
}

const AUTOMATIC = 'AUTOMATIC';
interface AutomaticEvent extends BaseEvent {
    type: typeof AUTOMATIC;
    /**
     * All the manipulations. Once one manipulation reaches its end, the event as a whole is
     * stopped.
     */
    manipulations: AutomaticManipulation[];
}

const CHOICE = 'CHOICE';
interface ChoiceEvent extends BaseEvent {
    type: typeof CHOICE;
    discussion: DialogDescriptor[];
    choice: ChoiceDescriptor;
}

const DIALOG = 'DIALOG';
interface DialogEvent extends BaseEvent {
    type: typeof DIALOG;
    discussion: DialogDescriptor[];
}

const MAP_CHANGE = 'MAP_CHANGE';
interface MapChangeEvent extends BaseEvent {
    type: typeof MAP_CHANGE;
    newMap: string;
    newPosition: (oldPosition: Coordinates) => Coordinates;
}

const NONE = 'NONE';
interface NoneEvent extends BaseEvent {
    type: typeof NONE;
}

const SHIFT = 'SHIFT';
interface ShiftEvent extends BaseEvent {
    type: typeof SHIFT;
    newPlane: (oldPlane?: number) => number;
}

export const PlayTypes = {
    /**
     * Play the sound even if something else is already playing. Same as not specifying a play
     * type.
     */
    normal: 'NORMAL',
    /** Play the sound and replace anything currently playing. */
    replace: 'REPLACE',
    /**
     * Unmute the specified track in the sound pack. If the sound doesn't exist in the current
     * sound pack, start the specified sound pack with the specified track unmuted.
     */
    unmute: 'UNMUTE',
    /**
     * Mute the specified track in the sound pack. If the sound doesn't exist in the current
     * sound pack, do nothing.
     */
    mute: 'MUTE',
    /**
     * Play the specified track in the sound pack and mute other playing tracks. If the sound
     * doesn't exist in the current sound pack, start the specified sound pack with the specified
     * track unmuted.
     */
    switch: 'SWITCH'
};

const SOUND = 'SOUND';
interface SoundEvent extends BaseEvent {
    type: typeof SOUND;
    sound?: SoundDescriptor,
    soundPack?: SoundPack,
    playType?: string
}

export const EventType = {
    automatic: AUTOMATIC as typeof AUTOMATIC,
    choice: CHOICE as typeof CHOICE,
    dialog: DIALOG as typeof DIALOG,
    mapChange: MAP_CHANGE as typeof MAP_CHANGE,
    none: NONE as typeof NONE,
    shift: SHIFT as typeof SHIFT,
    sound: SOUND as typeof SOUND
};

export const NoEvent: NoneEvent = {
    type: EventType.none,
    requiredKeys: [],
    forbiddenKeys: [],
    nextEvents: []
};

export type EventDescriptor =
    AutomaticEvent |
    ChoiceEvent |
    DialogEvent |
    MapChangeEvent |
    NoneEvent |
    ShiftEvent |
    SoundEvent;