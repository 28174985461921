import React from 'react';
import styled from 'styled-components';

type StyleProps = {
    centered: boolean
}

const Container = styled.div<StyleProps>`
    width: 100%;
    display: flex;
    flex-flow: row;
    flex: 1 1 auto;
    flex-wrap: wrap;
    ${(props) => props.centered && 'justify-content: center;'}
    margin: 10px 0;

    > * {
        margin: 0 10px;
    }
`;

type RowProps = {
    centered: boolean
}

export const Row: React.FC<RowProps> = (props) => (
    <Container centered={props.centered} >
        {props.children}
    </Container>
);