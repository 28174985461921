import {ClassicLink, Page, Row} from 'components';
import React, {useEffect} from 'react';
import {changeBackgroundImage} from 'redux/actions';
import {BACKGROUNDS} from 'redux/constants';
import {
    DiscordIcon,
    GmailIcon,
    SpotifyIcon,
    TwitchIcon,
    TwitterIcon,
    YoutubeIcon
} from 'styles/icons';

export const SocialPage: React.FC = () => {
    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.LISA);
    }, []);

    return (
        <Page align='left'>
            <h1>SOCIALS</h1>
            <p>
                {`Hey! If you're looking into getting in touch with me, there is no bad
                solution, but if you're looking for a quick answer you should definitely try
                sending me a message over on twitter or discord. On the other side, if you'd
                rather talk about a project in details, please reach out to me by email so I
                can write a proper answer.`}
            </p>
            <Row centered>
                <a target='_blank' rel='noopener noreferrer' href='https://twitter.com/PrettyPatterns_' title='Twitter'>
                    <TwitterIcon />
                </a>
                <a target='_blank' rel='noopener noreferrer' href='https://discordapp.com/invite/nQDPnzh' title='Discord'>
                    <DiscordIcon />
                </a>
                <a target='_blank' rel='noopener noreferrer' href='mailto:diego.cabrerapro@gmail.com' title='Gmail'>
                    <GmailIcon />
                </a>
                <a target='_blank' rel='noopener noreferrer' href='https://open.spotify.com/artist/4qwvSfN4vvXmOfepMFSCeX' title='Spotify'>
                    <SpotifyIcon />
                </a>
                <a target='_blank' rel='noopener noreferrer' href='https://www.twitch.tv/prettypatterns' title='Twitch'>
                    <TwitchIcon />
                </a>
                <a target='_blank' rel='noopener noreferrer' href='https://www.youtube.com/c/SomeGuyWhoPlaysPiano' title='Youtube'>
                    <YoutubeIcon />
                </a>
            </Row>
            <ClassicLink to={'/home'}>BACK</ClassicLink>
        </Page>
    );
};