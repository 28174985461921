import {action as toggleMenu} from 'redux-burger-menu';
import {BackgroundConstants, BackgroundDispatch} from 'redux/constants';
import {store} from 'redux/store';

const changeBackground: (backgroundUrl: string) => BackgroundDispatch = (backgroundUrl) => ({
    type: BackgroundConstants.changeBackground,
    backgroundUrl
});

export const changeBackgroundImage = (imageUrl: string) => {
    store.dispatch(changeBackground(imageUrl));
    store.dispatch(toggleMenu(false));
};