import React from 'react';
import styled from 'styled-components';

import {ModalProps} from './ModalProps';

const Container = styled.div`
    min-width: 320px;
    max-width: 400px;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.5em 2em;
    background: #ffffff;
    z-index: 101;
    border-radius: 10px;
`;

export const AlertModal: React.FC<ModalProps> = (props) => (
    <Container>
        <div>
            <h1>{props.title}</h1>
        </div>
        <div>
            <p>{props.text}</p>
        </div>
        <div>
            <button type='button' onClick={props.onConfirm}>{props.buttonText || 'Okay!'}</button>
        </div>
    </Container>
);