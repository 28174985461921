import {ModalProps} from 'components/modals/ModalProps';
import {ModalConstants, ModalDispatch} from 'redux/constants';
import {store} from 'redux/store';

const openModal: (modal: ModalProps) => ModalDispatch = (modal) => ({
    type: ModalConstants.openModal,
    modal
});

export const displayModal = (modal: ModalProps) => store.dispatch(openModal(modal));

const closeModal: () => ModalDispatch = () => ({
    type: ModalConstants.closeModal
});

export const hideModal = () => store.dispatch(closeModal());