import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {RootState} from 'redux/reducers';
import styled from 'styled-components';

const Container = styled.div`
    background-color: black;
    border-radius: 10px;
    width: 420px;
    height: 315px;
    margin: 20px auto;

    @media screen and (max-device-width: 600px) {
        width: 95%;
        height: 315px;
    }

    iframe {
        border-radius: 10px;
        max-width: 100%;
    }
`;

const mapStateToProps = (state: RootState) => ({
    isMusicPlaying: state.music.isPlaying
});

const connector = connect(mapStateToProps);

type PlaylistConnectedProps = ConnectedProps<typeof connector>;

type PlaylistProps = PlaylistConnectedProps & {
    name: string,
    shortUrl: string
}

const Playlist: React.FC<PlaylistProps> = (props) => (
    <Container>
        <iframe
            title={props.name}
            src={`https://www.youtube.com/embed/videoseries?list=${props.shortUrl}&rel=0`}
            width='420'
            height='315'
            allowFullScreen
            frameBorder={0}
        >
        </iframe>
        {props.children}
    </Container>
);

export const ConnectedPlaylist = connector(Playlist);