import {ClassicLink, Page} from 'components';
import React, {useEffect} from 'react';
import {changeBackgroundImage} from 'redux/actions';
import {BACKGROUNDS} from 'redux/constants';

export const Lost: React.FC = () => {
    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.NEON_BLOSSOM);
    }, []);

    return (
        <Page>
            <h1>You seem to have lost your way...</h1>
            <p>
                {`Whether it was an error or you tried to sneak somewhere you weren't
                supposed to be, there is nothing here.`}
            </p>
            <p>
                {`That being said, there are a few secrets to be found lying around and
                waiting for someone to find them, so you might as well keep looking!`}
            </p>
            <ClassicLink to={'/von'}>BACK</ClassicLink>
        </Page>
    );
};