import {ClassicLink, Column, DeadLink, Page, Row, Spring} from 'components';
import {ModalType} from 'components/modals/ModalProps';
import React, {Fragment, useEffect, useState} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {changeBackgroundImage, displayModal, setSound} from 'redux/actions';
import {BACKGROUNDS, SOUNDS} from 'redux/constants';
import {RootState} from 'redux/reducers';
import styled from 'styled-components';

export const Chapter1: React.FC = () => {
    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.NEON_BLOSSOM);
    }, []);

    return (
        <Page align='left'>
            <h1>Wednesday October 1st, 2053 - 7:03 PM</h1>
            <p>
                {`I unlocked the door and quickly entered the house. The sun
                outside had already set, but even with the feeble ambient light
                I immediately noticed the unusual amount of dust. I knew my
                father worked until late in the night, so I wasn't worried about
                him being here, but the state of the house made it look as if
                nobody had been living here for the past few months.`}
            </p>
            <p>
                {`Starting to question my father's whereabouts, I reached for the
                light switch and turned it on. But nothing happened. Was the power
                cut off or was the light bulb simply broken? Getting slightly
                anxious, I decided the answer could wait and went upstairs in the
                dark.`}
            </p>
            <p>
                {`Reaching the first floor, I entered my father's study and stopped
                right in my tracks: the room was in a complete mess with books
                thrown all over the floor. I instantly understood that someone had
                been here before me, who knew how many weeks or months before, and
                searched the place. Trying to contain the panic growing inside me,
                I started my own search hoping the perpetrator had left anything
                that could be relevant.`}
            </p>
            <p>
                {`After a few minutes, I had managed to find a few documents written
                by my father regarding the Patterns tech, but nothing that wasn't
                already available on the net. Nonetheless, I scanned them with my
                phone thinking that there might be some elements I had overlooked. I
                also found what looked like an old notebook on which I recognized Dad's
                handwriting. In it were what seemed like log entries for his research,
                but once again nothing that would help us. I did notice however that the
                last few pages had been torn off. Had the previous intruder taken them?
                Was I already too late?`}
            </p>
            <p>
                {`I finally turned my attention towards a massive safe that was lying
                in a corner of the room beside an elegant wooden desk. There were scratch
                marks on the side of the door which made me guess that someone had tried
                to force it open without success.`}
            </p>
            <p>
                {`The door had eight knobs each numbered from 0 to 9. The number of digits
                immediately made me think about a date, and I tried every birthday I could
                think of in our family as well as my parent's wedding day and even the day
                my mother died. But nothing worked. Was I wrong to think it was a date?
                Knowing Dad, there's no way he would have used something easy to guess.
                However his sense of logic would also mean that there was a way to find the
                answer.`}
            </p>
            <p><b>{'Concentrating once again, I started turning the knobs.'}</b></p>
            <ConnectedSafe />
            <Row centered={false}>
                <ClassicLink to={'/von'}>BACK</ClassicLink>
                <Spring />
                <DeadLink to={'/von/3'}>Give up</DeadLink>
            </Row>
        </Page>
    );
};

const Container = styled.div`
    background-color: hsla(0,0%,90%,1);
    background-image: linear-gradient(-45deg, hsla(0,0%,0%,0), hsla(0,0%,0%,.05) );
    background-size: 5px;
    border-radius: 5px;
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;

    .increment, .decrement {
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px auto;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        color: black;
        background-color: hsl(0,0%,90%);
        box-shadow: inset hsla(0,0%,30%,  1) 0  0px 0px 2px, /* border */
            inset hsla(0,0%,15%, .8) 0 -1px 5px 4px, /* soft SD */
            inset hsla(0,0%,100%,.7) 0  2px 1px 7px, /* top HL */
            hsla(0,0%, 0%,.15) 0 -5px 6px 4px, /* outer SD */
            hsla(0,0%,100%,.5) 0  5px 6px 4px; /* outer HL */
        transition: .2s;
    }

    .increment:active, .decrement:active {
        background-color: hsl(0,0%,80%);
        box-shadow: inset hsla(0,0%,30%,  1) 0  0px 0px 2px, /* border */
            inset hsla(0,0%,5%, .8) 0 -1px 5px 4px, /* soft SD */
            inset hsla(0,0%,90%,.7) 0  2px 1px 7px, /* top HL */
            hsla(0,0%, 0%,.15) 0 -5px 6px 4px, /* outer SD */
            hsla(0,0%,100%,.5) 0  5px 6px 4px; /* outer HL */
    }

    .digit {
        user-select: none;
        background-color: black;
        border-radius: 5px;
        margin: 10px;
    }
`;

const mapStateToProps = (state: RootState) => ({
    vaultCode: state.app.vaultCode
});

const connector = connect(mapStateToProps);

type SafeConnectedProps = ConnectedProps<typeof connector>;

type SafeProps = RouteComponentProps & SafeConnectedProps & {

}

const Safe: React.FC<SafeProps> = (props) => {
    const [combination, setCombination] = useState([0, 0, 0, 0, 0, 0, 0, 0]);

    const playSound = (index: number, match: string) => {
        if (!props.vaultCode) {
            return;
        }
        const vaultChar = props.vaultCode.charAt(index);
        if (vaultChar === match) {
            setSound(SOUNDS.lock2);
        } else {
            setSound(SOUNDS.lock);
        }
    };

    const incrementDigit = (index: number) => {
        const newCombination = combination.slice();
        if (newCombination[index] === 9) {
            newCombination[index] = 0;
        } else {
            newCombination[index]++;
        }
        setCombination(newCombination);
        playSound(index, newCombination[index].toString());
    };

    const decrementDigit = (index: number) => {
        const newCombination = combination.slice();
        if (newCombination[index] === 0) {
            newCombination[index] = 9;
        } else {
            newCombination[index]--;
        }
        setCombination(newCombination);
        playSound(index, newCombination[index].toString());
    };

    const goToNext = () => {
        props.history.push(`/von/${props.vaultCode}`);
    };

    const handleOpen = () => {
        let submittedCombination = '';
        combination.forEach((digit) => {
            submittedCombination += digit;
        });
        if (submittedCombination === props.vaultCode) {
            displayModal({
                title: 'You unlocked the safe!',
                text: '',
                type: ModalType.ALERT,
                buttonText: 'Open the safe',
                onConfirm: goToNext
            });
        } else {
            displayModal({
                title: 'The safe is still locked...',
                text: 'It seems this wasn\'t the right combination.',
                type: ModalType.ALERT,
                buttonText: 'Try again'
            });
        }
    };

    return (
        <Fragment>
            <Container>
                <Row centered={false}>
                    {combination.map((digit, index) => (
                        <Column width='8%' align='center' key={index}>
                            <div className='increment' onClick={() => incrementDigit(index)}>↷</div>
                            <div className='digit' >{digit}</div>
                            <div className='decrement' onClick={() => decrementDigit(index)}>⤻</div>
                        </Column>
                    ))}
                </Row>
            </Container>
            <Row centered={true}>
                <button onClick={handleOpen}>Open</button>
            </Row>
        </Fragment>
    );
};

const ConnectedSafe = connector(withRouter(Safe));