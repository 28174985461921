import React from 'react';
import styled from 'styled-components';

type StyleProps = {
    align: string
}

const Container = styled.div<StyleProps>`
    max-width: 600px;
    margin: auto auto;
    align-self: center;
    text-align: center;
    padding: 10px;
    background-color: rgb(38, 44, 120);
    background-color: rgb(38, 44, 120, 0.9);
    flex: 1 1 auto;
    border-radius: 10px;
    animation: fade-in 1.2s ease-in-out;

    @media screen and (max-device-width: 600px) {
        max-width: 100%;
        border-radius: unset;
    }

    p {
        font-family: Arial, Helvetica, sans-serif;
        ${(props) => `text-align: ${props.align};`}
    }

    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

type PageProps = {
    align?: string
}

export const Page: React.FC<PageProps> = (props) => (
    <Container align={props.align || 'center'}>
        {props.children}
    </Container>
);