import {AppConstants, AppDispatch} from 'redux/constants';

export class AppState {
    isStarted: boolean = false;
    vaultCode?: string;
}

function makeId(length) {
    let result = '';
    const firstCharacter = '123456789';
    const firstCharacterLength = firstCharacter.length;
    const characters = '0123456789';
    const charactersLength = characters.length;
    result += firstCharacter.charAt(Math.floor(Math.random() * firstCharacterLength));
    for (let i = 0; i < length - 1; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function AppReducer(
    state = new AppState(),
    action: AppDispatch
): AppState {
    if (!action) return state;
    switch (action.type) {
    case AppConstants.startApp:
        return {
            ...state,
            isStarted: true,
            vaultCode: makeId(8)
        };
    default:
        return state;
    }
}