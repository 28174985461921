import {ClassicLink, Page, Row, Spring} from 'components';
import React, {useEffect} from 'react';
import {changeBackgroundImage} from 'redux/actions';
import {BACKGROUNDS} from 'redux/constants';

export const Prologue: React.FC = () => {
    useEffect(() => {
        changeBackgroundImage(BACKGROUNDS.NEON_BLOSSOM);
    }, []);

    return (
        <Page align='left'>
            <h1>Wednesday October 1st, 2053 - 6:42 PM</h1>
            <p>
                {`I left the empty metro station and looked around, trying to remember
            the path to my destination. It had been a little over 5 years since I
            last came here, but the surrounding landscape didn't change that much.
            The place however felt much less lively than before, with barely anyone
            outside even at this time.`}
            </p>
            <p>
                {`I noticed a few construction sites at places where houses stood
            before. As part of the government's urbanization plan, single-story
            buildings where slowly but surely being replaced by large apartment
            complexes in order to answer the steady rise in the global population
            count.`}
            </p>
            <p>
                {`Precisely navigating the roads where I had spent most of my childhood,
            I quickly arrived in front of my father's home. It had a somewhat old
            feeling, exactly as I remembered. Dad had always seen the urbanization
            plans in a bad light and had strongly refused to sell the land. His position
            might have had an impact on the decisions, but who knew how long this house
            would stay here.`}
            </p>
            <p>
                {`With many thoughts in mind, I took out the key that I had kept ever since
            I had moved downtown to live on my own and approached the door.`}
            </p>
            <Row centered={false}>
                <ClassicLink to={'/von'}>BACK</ClassicLink>
                <Spring />
                <ClassicLink to={'/von/1'}>Enter the house</ClassicLink>
            </Row>
        </Page>
    );
};