import React from 'react';
import ReactModal from 'react-modal';
import {connect, ConnectedProps} from 'react-redux';
import {hideModal} from 'redux/actions';
import {RootState} from 'redux/reducers';
import styled from 'styled-components';

import {AlertModal} from './AlertModal';
import {ModalType} from './ModalProps';

const getBackgroundColor: (type: ModalType) => string = (type) => {
    const defaultBackground = 'rgb(255, 255, 255, 0.3)';
    if (!type) {
        return defaultBackground;
    }
    switch (type) {
    case ModalType.ALERT:
        return 'rgb(38, 44, 120, 0.7)';
    case ModalType.CONFIRM:
        return defaultBackground;
    case ModalType.ERROR:
        return defaultBackground;
    case ModalType.WARNING:
        return defaultBackground;
    default:
        return defaultBackground;
    }
};

type StyleProps = {
    modalType: ModalType
}

const Container = styled.div<StyleProps>`
    position: fixed;
    background-color: ${(props) => getBackgroundColor(props.modalType)};
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
`;

const MODAL_TYPES = {
    alert: AlertModal
};

const mapStateToProps = (state: RootState) => ({
    modal: state.modal.modal
});

const connector = connect(mapStateToProps);

type ModalContainerConnectedProps = ConnectedProps<typeof connector>;

type ModalContainerProps = ModalContainerConnectedProps & {

}

type ModalContainerState = {
    isOpen: boolean
}

class ModalContainer extends React.Component<ModalContainerProps, ModalContainerState> {
    constructor(props: ModalContainerProps) {
        super(props);
        this.state = {
            isOpen: props && true
        };
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(e) {
        hideModal();
        if (this.props.modal && this.props.modal.onConfirm) {
            this.props.modal.onConfirm(e);
        }
    }

    render() {
        if (!this.props.modal) {
            return null;
        }
        const SpecifiedModal = MODAL_TYPES[this.props.modal.type];
        return (
            <Container modalType={this.props.modal.type}>
                <ReactModal
                    isOpen={this.state.isOpen}
                    contentLabel='Example Modal'
                    ariaHideApp={false}
                    overlayClassName='modal fade show'
                    bodyOpenClassName='modal-open'
                    className='modal-dialog modal-dialog-centered'
                >
                    <SpecifiedModal
                        {...this.props.modal}
                        onConfirm={this.closeModal}
                    />
                </ReactModal>
            </Container>
        );
    }
}

export const ConnectedModalContainer = connector(ModalContainer);