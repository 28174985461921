import React from 'react';
import styled from 'styled-components';

import {SpriteProps} from '../SpriteTypes';

const Container = styled.div``;

export const InvisibleWallSprite: React.FC<SpriteProps> = (props) => (
    <Container className={`${props.identifier}`}>
        <div className=''></div>
    </Container>
);