const START_SOUND = 'START_SOUND';
const CHANGE_SOUND = 'CHANGE_SOUND';

interface StartSoundDispatch {
    type: typeof START_SOUND;
}

interface ChangeSoundDispatch {
    type: typeof CHANGE_SOUND;
    src?: string;
}

export const SOUNDS = {
    metro: '/sounds/effects/metro.mp3',
    door: '/sounds/effects/door.mp3',
    lock: '/sounds/effects/lock.mp3',
    lock2: '/sounds/effects/lock2.mp3',
    paper: ''
};

export const SoundConstants = {
    startSound: START_SOUND as typeof START_SOUND,
    changeSound: CHANGE_SOUND as typeof CHANGE_SOUND
};

export type SoundDispatch =
    StartSoundDispatch |
    ChangeSoundDispatch;