import React, {useEffect} from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {RootState} from 'redux/reducers';
import styled from 'styled-components';

type StyleProps = {
    imageUrl: string,
    toggle: boolean
}

const Container = styled.div<StyleProps>`
    position: fixed;
    z-index: -10;
    left: 0;
    top: 0;
    min-height: 100vh;
    min-width: 100vw;
    background: rgb(38,44,120);
    ${(props) => `background-image: url(${props.imageUrl});`}
    ${(props) => `background-image:
        url(${props.imageUrl}),
        linear-gradient(
            180deg,
            rgba(38,44,120,1) 0%,
            rgba(85,53,128,1) 10%,
            rgba(120,60,134,1) 50%,
            rgba(179,71,144,1) 90%,
            rgba(221,79,152,1) 100%
        );
    `}
    background-position: 44% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    ${(props) => `opacity: ${props.toggle ? '1' : '0'};`}
    transition: opacity 0.9s ease-in-out;
`;

const mapStateToProps = (state: RootState) => ({
    imageUrl1: state.background.backgroundUrl1,
    imageUrl2: state.background.backgroundUrl2,
    toggle: state.background.toggle
});

const connector = connect(mapStateToProps);

type BackgroundContainerConnectedProps = ConnectedProps<typeof connector>;

type BackgroundContainerProps = BackgroundContainerConnectedProps & {

}

const BackgroundContainer: React.FC<BackgroundContainerProps> = (props) => {
    useEffect(() => {

    }, [props]);

    return (
        <React.Fragment>
            <Container imageUrl={props.imageUrl1} toggle={!props.toggle} />
            <Container imageUrl={props.imageUrl2} toggle={props.toggle} />
        </React.Fragment>
    );
};

export const ConnectedBackgroundContainer = connector(BackgroundContainer);