import {BackgroundConstants, BackgroundDispatch, BACKGROUNDS} from 'redux/constants';

export class BackgroundState {
    backgroundUrl1: string = BACKGROUNDS.NEON_BLOSSOM;
    backgroundUrl2: string = BACKGROUNDS.NEON_BLOSSOM;
    toggle: boolean = false;
}

export function BackgroundReducer(
    state = new BackgroundState(),
    action: BackgroundDispatch
): BackgroundState {
    if (!action) return state;
    switch (action.type) {
    case BackgroundConstants.changeBackground:
        if (state.toggle) {
            if (state.backgroundUrl2 === action.backgroundUrl) {
                return state;
            }
            return {
                ...state,
                toggle: false,
                backgroundUrl1: action.backgroundUrl
            };
        }
        if (state.backgroundUrl1 === action.backgroundUrl) {
            return state;
        }
        return {
            ...state,
            toggle: true,
            backgroundUrl2: action.backgroundUrl
        };

    default:
        return state;
    }
}