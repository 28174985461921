import {ObjectSprites} from 'von-game/sprites';
import {SpriteDescriptor} from 'von-game/sprites/SpriteTypes';
import {DIALOG_SPEED, DialogDescriptor} from 'von-game/utils/DialogTypes';
import {AutomaticManipulation, EventDescriptor, EventType} from 'von-game/utils/EventTypes';

// Dialogs
const menuDialog: DialogDescriptor[] = [
    {
        name: 'Lisa',
        lines: [
            {speed: DIALOG_SPEED.FAST, text: 'The cafe displayed it\'s menu on a slate.'}
        ],
        leftSprite: {
            path: '/assets/game/dialogSprites/Lisa.png',
            position: {
                x: 0, y: 0
            }
        }
    },
    {
        name: 'Lisa',
        lines: [
            {speed: DIALOG_SPEED.FAST, text: 'Most places use electronic displays nowadays, so it feels a bit out of place.'}
        ],
        leftSprite: {
            path: '/assets/game/dialogSprites/Lisa.png',
            position: {
                x: 0, y: 0
            }
        }
    }
];

// Movements
/*
const carMovement: AutomaticManipulation = {
    identifier: 'neon-blossom-map-car',
    checkEnd: (currentPosition, counter) => {
        // As long as the car is still on the map, the movement is ongoing
        if (currentPosition.x < 800) {
            return false;
        }
        // If the car is out of the map, wait for the next loop to start again
        return counter % 1000 < 900;
    },
    positionDefinition: (currentPosition, counter) => {
        // As long as the car is still on the map, keep going right
        if (currentPosition.x < 800) {
            return {x: currentPosition.x + 6, y: currentPosition.y};
        }
        // If a new loop starts, put the car back to the start
        if (counter % 1000 > 900) {
            return {x: -400, y: currentPosition.y};
        }
        // By default, return the current position
        return currentPosition;
    },
    walking: true
};
*/

const blocPathDuringCarPass: AutomaticManipulation = {
    identifier: 'neon-blossom-map-crossing-stop',
    positionDefinition: (currentPosition, counter, eventKeys) => {
        if (eventKeys.indexOf('car-passing') > -1) {
            return {
                ...currentPosition,
                plane: 100
            };
        }
        return {
            ...currentPosition,
            plane: 1
        };
    }
};

// Events
const menuEvent: EventDescriptor = {
    type: EventType.dialog,
    hitbox: {
        start: {x: 302, y: 88},
        end: {x: 302, y: 90}
    },
    manual: true,
    discussion: menuDialog,
    requiredKeys: [],
    forbiddenKeys: [],
    nextEvents: []
};

const blocPathDuringCarPassEvent: EventDescriptor = {
    type: EventType.automatic,
    requiredKeys: [],
    forbiddenKeys: [],
    manipulations: [blocPathDuringCarPass],
    nextEvents: []
};

/*
const carMovementEvent: EventDescriptor = {
    type: EventType.automatic,
    requiredKeys: [],
    forbiddenKeys: ['crossing'],
    manipulations: [carMovement],
    nextEvents: [],
    eventKey: 'car-passing'
};
*/

// Sprites
const MallFrontColumn: SpriteDescriptor = {
    identifier: 'neon-blossom-map-mall-front-column',
    sprite: ObjectSprites.MallFrontColumnSprite,
    hitbox: {
        start: {x: 0, y: 87},
        end: {x: 75, y: 95}
    },
    position: {x: 358, y: 320},
    events: []
};

const MallLeftColumn: SpriteDescriptor = {
    identifier: 'neon-blossom-map-mall-left-column',
    sprite: ObjectSprites.MallLeftColumnSprite,
    hitbox: {
        start: {x: 0, y: 73},
        end: {x: 63, y: 93}
    },
    position: {x: 140, y: 322},
    events: []
};

const MallRightColumn: SpriteDescriptor = {
    identifier: 'neon-blossom-map-mall-right-column',
    sprite: ObjectSprites.MallRightColumnSprite,
    hitbox: {
        start: {x: 0, y: 0},
        end: {x: 185, y: 95}
    },
    position: {x: 542, y: 320},
    events: []
};

const MallTop: SpriteDescriptor = {
    identifier: 'neon-blossom-map-mall-top',
    sprite: ObjectSprites.MallTopSprite,
    hitbox: {
        start: {x: 24, y: 0},
        end: {x: 550, y: 387},
        plane: 1
    },
    position: {x: 126, y: 0},
    events: []
};

const CafeTop: SpriteDescriptor = {
    identifier: 'neon-blossom-map-cafe-top',
    sprite: ObjectSprites.CafeTopSprite,
    hitbox: {
        start: {x: 16, y: 82},
        end: {x: 159, y: 223}
    },
    position: {x: 736, y: 170},
    events: []
};

const Menu: SpriteDescriptor = {
    identifier: 'neon-blossom-map-menu',
    sprite: ObjectSprites.MenuSprite,
    hitbox: {
        start: {x: 2, y: 16},
        end: {x: 23, y: 23}
    },
    position: {x: 742, y: 384},
    events: [menuEvent]
};

const Chair1: SpriteDescriptor = {
    identifier: 'neon-blossom-map-chair1',
    sprite: ObjectSprites.Chair1Sprite,
    hitbox: {
        start: {x: 0, y: 12},
        end: {x: 19, y: 29}
    },
    position: {x: 766, y: 384},
    events: []
};

const Chair2: SpriteDescriptor = {
    identifier: 'neon-blossom-map-chair2',
    sprite: ObjectSprites.Chair2Sprite,
    hitbox: {
        start: {x: 0, y: 14},
        end: {x: 23, y: 29}
    },
    position: {x: 814, y: 374},
    events: []
};

const Table: SpriteDescriptor = {
    identifier: 'neon-blossom-map-table',
    sprite: ObjectSprites.TableSprite,
    hitbox: {
        start: {x: 0, y: 6},
        end: {x: 27, y: 33}
    },
    position: {x: 786, y: 378},
    events: []
};

const PatternsLeftTop: SpriteDescriptor = {
    identifier: 'neon-blossom-map-patterns-left-top',
    sprite: ObjectSprites.PatternsLeftTopSprite,
    hitbox: {
        start: {x: 6, y: 0},
        end: {x: 303, y: 407}
    },
    position: {x: 906, y: 0},
    events: []
};

const Plot1: SpriteDescriptor = {
    identifier: 'neon-blossom-map-plot1',
    sprite: ObjectSprites.PlotSprite,
    hitbox: {
        start: {x: 2, y: 16},
        end: {x: 13, y: 21}
    },
    position: {x: 8, y: 282},
    events: []
};

const Plot2: SpriteDescriptor = {
    identifier: 'neon-blossom-map-plot2',
    sprite: ObjectSprites.PlotSprite,
    hitbox: {
        start: {x: 2, y: 16},
        end: {x: 13, y: 21}
    },
    position: {x: 168, y: 282},
    events: []
};

const Plot3: SpriteDescriptor = {
    identifier: 'neon-blossom-map-plot3',
    sprite: ObjectSprites.PlotSprite,
    hitbox: {
        start: {x: 2, y: 16},
        end: {x: 13, y: 21}
    },
    position: {x: 200, y: 282},
    events: []
};

const Plot4: SpriteDescriptor = {
    identifier: 'neon-blossom-map-plot4',
    sprite: ObjectSprites.PlotSprite,
    hitbox: {
        start: {x: 2, y: 16},
        end: {x: 13, y: 21}
    },
    position: {x: 232, y: 282},
    events: []
};

const Plot5: SpriteDescriptor = {
    identifier: 'neon-blossom-map-plot5',
    sprite: ObjectSprites.PlotSprite,
    hitbox: {
        start: {x: 2, y: 16},
        end: {x: 13, y: 21}
    },
    position: {x: 264, y: 282},
    events: []
};

const StreetLamp1: SpriteDescriptor = {
    identifier: 'neon-blossom-map-lamp1',
    sprite: ObjectSprites.StreetLampSprite,
    hitbox: {
        start: {x: 24, y: 89},
        end: {x: 31, y: 94}
    },
    position: {x: 4, y: 190},
    events: []
};
const StreetLamp2: SpriteDescriptor = {
    identifier: 'neon-blossom-map-lamp2',
    sprite: ObjectSprites.StreetLampSprite,
    hitbox: {
        start: {x: 24, y: 89},
        end: {x: 31, y: 94}
    },
    position: {x: 228, y: 190},
    events: []
};
const TrafficLight: SpriteDescriptor = {
    identifier: 'neon-blossom-map-light',
    sprite: ObjectSprites.TrafficLightSprite,
    hitbox: {
        start: {x: 2, y: 56},
        end: {x: 13, y: 61}
    },
    position: {x: 40, y: 242},
    events: []
};

const InvisibleWall: SpriteDescriptor = {
    identifier: 'neon-blossom-map-crossing-stop',
    sprite: ObjectSprites.InvisibleWallSprite,
    hitbox: {
        start: {x: 0, y: 0},
        end: {x: 67, y: 83}
    },
    position: {x: 78, y: 314, plane: 99},
    events: [],
    movementEvent: blocPathDuringCarPassEvent
};

export const NeonBlossomMapSprites = [
    MallFrontColumn,
    MallLeftColumn,
    MallRightColumn,
    MallTop,
    CafeTop,
    Menu,
    Chair1,
    Chair2,
    Table,
    PatternsLeftTop,
    Plot1,
    Plot2,
    Plot3,
    Plot4,
    Plot5,
    StreetLamp1,
    StreetLamp2,
    TrafficLight,
    InvisibleWall
];